.mainContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100000000;
	max-height: max-content;
	min-height: 100%;
	width: 100%;
	background-color: #121528;
}

.navbar {
	display: grid;
	grid-template-columns: 10% 90%;
	padding: 1rem;
	background-color: #030413;
}

.icon {
	margin: auto 0;
}

.head {
	font-size: 25px;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.header {
	text-align: center;
	margin: auto 0;
}

.topBlock {
	padding: 0 1rem;
}

.iconBox {
	height: 72px;
	width: 72px;
	background-color: #030413;
	position: relative;
	border-radius: 50%;
}

.camera {
	position: absolute;
	right: 3px;
	bottom: 3px;
}

.block {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	color: #d9def4;
	padding: 1rem;
}

.blockHead {
	font-size: 12px;
}

.contentBlock {
	background-color: #030413;
	padding: 0.75rem 1rem;
	font-size: 17px;
	color: #7f89be;
	width: 100%;
	border: none;
}

.privateBlock {
	display: grid;
	grid-template-columns: 10% 70% 20%;
	background-color: #030413;
	padding: 0 1rem;
}

.note {
	font-size: 12px;
	color: #d9def4;
	padding: 1rem;
}

.username {
	color: #d9def4;
	margin: auto 0;
}

.button {
	width: 100%;
	color: white;
	font-size: 14px;
	border: none;
	padding: 1rem;
	border-radius: 100px;
	text-align: center;
}
