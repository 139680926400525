.contHide {
	display: none;
	transform: translateX(100%);
}

.contShow {
	width: 25%;
	display: flex;
	position: absolute;
	flex-direction: column;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 200;
	/* box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.02); */
	box-shadow: -2px 5px 10px #121528;
	transform: translateX(0);
	transition: transform 0.3s ease-out;
}

.darkbg{
	background-color: #121528;
}

.lgbg{
	background-color: #fff;
}

.darkt{
	color: #d9def4;
}

.lgt{
	color: #0d0f22;
}

.darks{
	color: #7f89be;
}

.lgs{
	color: #4A517E;
}

.searchCont {
	align-items: center;
	color: #7f89be;
	padding: 0.5rem;
	margin: 0 0.5rem;
	border-radius: 6px;
	display: flex;
	border: 1px solid #7f89be;
}

.searchCont .input {
	border: none;
	border-radius: 6px;
	outline: none;
	font-size: 0.75rem;
	color: #7f89be;
	flex: 1;
	padding-left: 10px;
	font-weight: 400;
	font-family: "Poppins";
	font-style: normal;
	background-color: transparent;
}

.searchCont .input::placeholder {
	color: #7f89be;
}
.no_result {
	color: #7f89be;
	text-align: center;
	font-size: 0.875rem;
}
.searchResult {
	margin: 0;
	padding: 0.5rem;
	flex: 1;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-top: 15px;
}

.searchResult::-webkit-scrollbar {
	width: 5px;
}
.searchResult::-webkit-scrollbar-thumb {
	background-color: #ff5b5b;
}

.loader {
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	height: 40px;
	width: 40px;
	padding: 10px;
}

.loaderContainer {
	top: 3rem;
	display: flex;
	justify-content: center;
	position: absolute;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.contShow {
		width: 100%;
	}
}
