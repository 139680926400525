@import "../../../Themes/ThemeSelector.scss";

.inputfieldcontainer {
	position: relative;
	.pre {
		display: flex;
		justify-content: space-between;
		font-weight: 500 !important;
		font-family: inter;
		font-size: 0.85rem;
		color: rgba(105, 111, 121, 1);
		// margin-bottom: 6px;
		p {
			margin-bottom: 0;
			line-height: 1.6rem;
		}
		.label {
			padding-left: 3px;
			color: #7f89be;
		}
	}

	.showpassword {
		position: absolute;
		top: 25%;
		right: 2.5%;
		cursor: pointer;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		box-sizing: border-box;
		padding: 7px 5px 5px 5px;
	}
	.showpassworderror {
		top: 13%;
	}
	.clicked {
		background-color: var(--backgroud-color);
		border: 1px solid var(--secondary);
	}

	.dark {
		.inputfield {
			width: 100%;
			position: relative;
			input {
				font-family: inter;
				font-size: 0.85rem;
				padding: 13px 35px 13px 20px;
				background: transparent;
				border-radius: 4px;
				border: 1px solid #8692a6;
				box-sizing: border-box;
				color: #7f89be;
				width: 100%;

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					box-shadow: 0 0 0 30px #121528 inset !important;
					-webkit-box-shadow: 0 0 0 30px #121528 inset !important;
					-webkit-text-fill-color: #d9def4 !important;
				}
			}
		}
		.invalid {
			border-color: red;
		}
		.errorText {
			font-family: inter;
			font-size: 0.8rem;
			margin-top: 5px;
			color: red !important;
		}
		.dropdown {
			width: 100%;
			// max-height: 200px;
			border: 1px solid #8692a6;
			position: absolute;
			// max-height: ;
			background-color: white;
			overflow-y: auto;
			.loader {
				padding: 1rem;
				display: flex;
				justify-content: center;
			}
			z-index: 1;
			.item {
				padding: 0.6rem 1rem;
				font-size: 0.9rem;
				border-bottom: 1px solid #eee;
				&:hover {
					background-color: #eee;
				}
			}
		}
	}

	.lg {
		.inputfield {
			width: 100%;

			input {
				font-family: inter;
				font-size: 0.85rem;
				padding: 13px 35px 13px 20px;
				background: transparent;
				border-radius: 4px;
				border: 1px solid #8692a6;
				box-sizing: border-box;
				color: #7f89be;
				width: 100%;

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px #fff inset !important;
					-webkit-text-fill-color: #0d0f22 !important;
				}
			}
		}
		.invalid {
			border-color: red;
		}
		.errorText {
			font-family: inter;
			font-size: 0.8rem;
			margin-top: 5px;
			color: red !important;
		}
		.dropdown {
			width: 100%;
			// max-height: 200px;
			border: 1px solid #8692a6;
			position: absolute;
			// max-height: ;
			background-color: white;
			overflow-y: auto;
			.loader {
				padding: 1rem;
				display: flex;
				justify-content: center;
			}
			z-index: 1;
			.item {
				padding: 0.6rem 1rem;
				font-size: 0.9rem;
				border-bottom: 1px solid #eee;
				&:hover {
					background-color: #eee;
				}
			}
		}
	}
}

.gradientbtn {
	width: 100%;
	font-weight: 500;
	padding: 0.9rem;
	border: none;
	border-radius: 5px;
	font-size: 0.8rem;
	color: white;
	background: linear-gradient(225deg, #5bceff 0%, #5b6cff 100%);
	&:disabled {
		opacity: 0.5;
	}
}
