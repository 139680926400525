.container {
	display: grid !important;
	grid-template-columns: 20% 80%;
	height: 100%;
}
.exploreContainer {
	height: 100%;
	background-color: #150d29;
	margin-left: 0.25rem;
}

.left {
	background-color: #030413;
	display: flex;
	flex-direction: column;
}

.right {
	background-color: #150d29;
	color: #d9def4;
	padding: 1rem 0.75rem;
	position: relative;
	height: 100%;
}

.groupIconBox {
	text-align: center;
	position: relative;
}

.groupIconBorder {
	position: absolute;
	top: 1rem;
	bottom: 1rem;
	width: 3px;
	background-color: #3f5efb;
	border-radius: 12px 0px 12px;
}

.groupIcon {
	width: 36px;
	height: 36px;
	margin: 1rem auto;
	border-radius: 7px;
}

.groupIconBg {
	background-color: #d9def4 !important;
	color: #0d0f22 !important;
}

.groupAddBox {
	width: 36px;
	height: 36px;
	margin: 1rem auto;
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	border-radius: 50%;
}

.groupName {
	font-weight: 400;
	font-size: 20px;
}

.groupInfoBox {
	background-color: #0d0f22;
	padding-left: 0.5rem;
}

.groupInfo:nth-child(1) {
	border-top: none !important;
}

.groupInfo {
	padding: 0.5rem 0.5rem 0.5rem 0;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	border-top: 0.2px solid #161b38;
}

.bottomBlock {
	position: absolute;
	bottom: 2rem;
	width: 90%;
}

.button {
	display: flex;
	padding: 1rem;
	border: 1px solid #6e88fc;
	color: #6e88fc;
	border-radius: 100px;
	text-align: center;
	width: 100%;
	justify-content: center;
	cursor: pointer;
}
.button1 {
	display: flex;
	padding: 1rem;
	border: 1px solid #bf1d27;
	color: #bf1d27;
	border-radius: 100px;
	text-align: center;
	width: 100%;

	justify-content: center;
	cursor: pointer;
}
.buttonLabel {
	margin-left: 0.5rem;
}

.exploreBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	padding: 0.4rem 0;
	cursor: pointer;
	border-radius: 50rem;
	margin-bottom: 10px;
	padding-right: 5px;
	margin-left: -0.5rem;
	padding: 0.4rem;
	justify-content: space-between;
	background-color: #0d0f22;
}

.grpname {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: var(--heading-color2);
}

.editPhoto {
	margin-left: 0.4rem;
}

.editPhoto img {
	width: 1rem;
	height: 1rem;
}

.exploreHeader {
	display: grid;
	grid-template-columns: 10% 90%;
}
