.mainContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100000000;
	max-height: max-content;
	min-height: 100%;
	width: 100%;
	background-color: #121528;
}

.topBlock {
	padding: 1rem;
	display: grid;
	grid-template-columns: 30% 70%;
}
.grpName {
	margin: auto 0;
	background-color: #030413;
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	color: #d9def4;
}
.iconBox {
	height: 72px;
	width: 72px;
	background-color: #030413;
	position: relative;
	border-radius: 50%;
}

.camera {
	position: absolute;
	right: 3px;
	bottom: 3px;
}
.icon {
	margin: auto 0;
}
.navbar {
	display: grid;
	grid-template-columns: 10% 90%;
	padding: 1rem;
	background-color: #030413;
}

.block {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	color: #d9def4;
	padding: 1rem;
}

.blockHead {
	font-size: 12px;
}

.header {
	text-align: center;
	margin: auto 0;
}
.userBlock {
	display: grid;
	grid-template-columns: 10% 90%;
	background-color: #030413;
	padding: 0 1rem;
	font-size: 16px;
}
.usernameBlock {
	margin: auto 0.5rem;
}
.username {
	color: #d9def4;
}

.userIcon {
	width: 36px;
	height: 36px;
	margin: 1rem auto;
}

.contentBlock {
	background-color: #030413;
	padding: 1rem;
	font-size: 16px;
	color: #7f89be;
	border: none;
	width: 100%;
}

.privateBlock {
	display: grid;
	grid-template-columns: 10% 70% 20%;
	background-color: #030413;
	padding: 0 1rem;
}

.subHead {
	color: #d9def4;
	padding: 1rem;
	font-size: 12px;
}

.memberBlock {
	display: grid;
	grid-template-columns: 12% 88%;
	background-color: #0d0f22;
	margin: 0.5rem 1rem;
	padding: 0 1rem;
	font-size: 16px;
	border-radius: 100px;
}

.addBlock {
	display: grid;
	grid-template-columns: 12% 88%;
	padding: 1rem;
}
.addIconBox {
	height: 36px;
	width: 36px;
	margin: auto 0;
	background-color: #0d0f22;
	border-radius: 50%;
}

.userBio {
	color: #7f89be;
	font-size: 11px;
	display: block;
}
.addUser {
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-size: 14px;
}

.note {
	font-size: 12px;
	color: #d9def4;
	padding: 1rem;
}

.button {
	width: 100%;
	color: white;
	font-size: 14px;
	border: none;
	padding: 1rem;
	border-radius: 100px;
	text-align: center;
}

.button1 {
	background-color: #121528;
	width: 100%;
	color: white;
	font-size: 14px;
	border: none;
	padding: 1rem;
	border-radius: 100px;
	text-align: center;
	border: 1px solid #ab47bc;
}
