.subHead {
	margin: 1rem auto 2rem auto;
	display: flex;
	align-items: center;
}

.subHead h2 {
	margin: 0;
	margin-left: 1rem;
	font-size: 1.25rem;
	color: var(--text-primary);
}

.addContainer {
	margin: 0;
	width: 100%;
}

.statusListDk,
.statusListLg {
	margin-top: 5%;
	padding-right: 10px;
	padding-bottom: 2rem;
	max-height: 49vh;
	overflow-y: auto;
}

.searchBox {
	padding: 0.35rem 0.75rem;
	bottom: 0px;
	left: 0;
	align-items: center;
	position: absolute;
	display: flex;
	width: 94%;
	border: 1px solid var(--secondary);
	border-radius: 8px;
	margin: 0 5px;
}

.mobileSearchBox {
	margin: 1rem 0;
	width: 100%;
	display: flex;
	padding: 0 0.5rem;
}

.mobileSearchBox .searchField {
	font-size: 1rem;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

input::placeholder {
	color: var(--secondary);
}

.statusListDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.statusListDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

.statusListLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.statusListLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.addStatusContainer {
	width: 100%;
	padding: 1.05rem 0 2.5rem;
	justify-content: center;
	align-items: center;
	position: relative;
}

.para {
	height: 10vh;
	margin: 10px 10px 25px 10px;
	color: #d9def4;
}

@media screen and (max-width: 768px) {
	.addStatusContainer {
		display: none;
	}

	.statusListDk,
	.statusListLg {
		max-height: 75vh;
		padding: 0 15px;
		width: 100%;
		margin-top: 0;
	}
}
