.heading {
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
}

.name {
	margin: 0;
	margin-left: 5px;
	color: #222;
	font-size: 0.75rem;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
}

.darkText {
	color: #d9def4 !important;
}

.lgText {
	color: #0d0f22 !important;
}

.count {
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: var(--heading-color2);
}

.footerRight {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	float: right;
}

.users,
.likes,
.comments,
.date {
	margin-left: 13px;
	display: flex;
}

.users {
	margin-left: 0;
	margin-right: auto;
	width: 100%;
}

.countText {
	margin-left: 5px;
}

.image {
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	box-sizing: border-box;
	border-radius: 6px;
}

.username {
	padding-left: 1px;
	font-size: 0.65rem;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0.1px;
}

.time {
	font-size: 0.665rem;
	line-height: 0.75rem;
	font-weight: 600;
	color: var(--heading-color2);
	margin-left: auto;
	margin-bottom: 5px;
}

.hoverBtn {
	color: #7f89be !important;
}

.heading:hover .hoverBtn:hover {
	color: var(--heading-color2);
}

.popoverbtn {
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: transparent;
}

.popoverbtn:hover {
	color: var(--heading-color2);
}

.popoverCap {
	font-size: 0.75rem;
	color: var(--secondary);
}

.content {
	padding: 1rem 0;
	font-weight: 400;
	line-height: 1.3rem;
	overflow: hidden;
	font-weight: 400;
	text-overflow: -o-ellipsis-lastline;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
	height: 5.4rem;
}

.content1 {
	padding: 1rem 0;
	font-weight: 400;
	line-height: 1.3rem;
	overflow: hidden;
	font-weight: 400;
	text-overflow: -o-ellipsis-lastline;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
	height: 5.4rem;
}

.content2 {
	padding: 1rem 0;
	font-weight: 400;
	line-height: 1.3rem;
	overflow: hidden;
	font-weight: 400;
	-webkit-line-clamp: 3;
	text-overflow: -o-ellipsis-lastline;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
	height: 5.4rem;
}

.content:before {
	content: "";
}

.content:after {
	content: "";
}
