/* @font-face {
  font-family: Avenir-Heavy;
  src: url("./fonts/AvenirNext-Heavy.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Avenir-Bold;
  src: url("./fonts/AvenirNextLTPro-Bold.otf");
  font-weight: 100;
}

@font-face {
  font-family: Avenir-Demibold;
  src: url("./fonts/AvenirNext-DemiBold.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Avenir-It;
  src: url("./fonts/AvenirNextLTPro-It.otf");
  font-weight: 100;
}

@font-face {
  font-family: Avenir-Regular;
  src: url("./fonts/AvenirNextLTPro-Regular.otf");
  font-weight: 100;
}

@font-face {
  font-family: Metropolis-Medium;
  src: url("./fonts/Metropolis-Medium.otf");
  font-weight: 100;
}

@font-face {
  font-family: BebasNeue;
  src: url("./fonts/BebasNeue-Regular.ttf");
  font-weight: 100;
}

@font-face {
  font-family: inter;
  src: url("./fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: inter;
  src: url("./fonts/Inter-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: inter;
  src: url("./fonts/Inter-Regular.ttf");
  font-weight: 400;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
* {
	font-family: "Poppins";
}

html {
	scrollbar-width: none;
}

html::-webkit-scrollbar {
	width: 0;
}

.MuiFormControl-root {
	width: 100%;
}

.MuiOutlinedInput-multiline {
	padding: 0 !important;
}

.MuiInputBase-multiline {
	padding: 0;
}
.MuiTooltip-tooltipPlacementTop {
	font-size: 0.8rem !important;
	margin-bottom: -0.5rem !important;
}
input[value="0000-00-00"]::-webkit-datetime-edit {
	color: transparent;
}
.MuiAccordion-root:before {
	top: 1px;
	left: 0;
	right: 0;
	height: 1px !important;
	content: "";
	opacity: 0 !important;
	position: absolute;
	transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: rgba(255, 255, 255, 0.12);
}

.App {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	font-family: "Poppins", sans-serif;
}

.full-height {
	height: 100vh;
	overflow: auto;
}

#finish {
	position: "fixed";
	height: "10px";
	width: "10px";
	background: "transparent";
	z-index: -10;
	top: "45vh";
	left: "45vw";
}
.bottonText {
	text-transform: none !important;
	font-size: 0.9rem !important;
}
.App::-webkit-scrollbar {
	width: 0;
	display: none;
}
* {
	outline: none !important;
}

a {
	text-decoration: none;
	color: black;
	height: 100%;
}

a:hover {
	text-decoration: none !important;
	color: black;
}

.trigger {
	box-shadow: none !important;
	padding: 0 !important;
}

fieldset {
	border: 0 !important;
}
.Info_container__3keoQ {
	border: none !important;
}
.cursor-pointer:hover {
	cursor: pointer;
}

.MuiChip-deleteIcon {
	color: #7f89be !important;
}

input::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

textarea {
	-webkit-user-select: auto;
}

.bm-menu-wrap {
	left: 0px !important;
	top: 0px !important;
	bottom: 0px;
	z-index: 1000000 !important;
	width: 80% !important;
}

.bm-item-list .MuiAvatar-root {
	border-radius: 30% !important;
}
