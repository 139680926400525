.title {
	font-size: 21px;
	font-family: Avenir-Bold;
	margin: 0 1rem 2rem;
	text-align: center;
}

.inputContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.currentImage {
	border-radius: 0.4rem;
	margin-bottom: 1rem;
}

.btnAccept,
.btnReject {
	font-size: 1rem;
	color: #fff;
	background: linear-gradient(67deg, #ff5b5b 0%, #dba3db 100%);
	border: 0px transparent;
	border-radius: 5px;
	box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
}

.btnReject {
	color: #333;
	background: linear-gradient(90deg, #ece9e6, #ffffff) !important;
	box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
}

.deleteAccount {
	display: flex;
	margin-top: 1rem;
	justify-content: center;
	opacity: 0.5;
	cursor: pointer;

	align-items: center;
}

@media only screen and (max-width: 600px) {
	.deleteAccount {
		margin: 0;
	}

	.deleteAccountText {
		display: none;
	}

	.passwordFields {
		width: 80%;
	}
}
