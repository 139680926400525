.wrapper {
	margin-top: 1em;
	display: flex;
	position: relative;
	height: 100%;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 90.9vh;
	display: flex;
	overflow: hidden;
	position: relative;
}

.header {
	display: flex;
	font-weight: 400;
	padding: 1rem 0 0 0.5rem;
}

.header h2 {
	font-weight: 400;
	margin-left: 5px;
	font-size: 1.25rem;
	margin-bottom: 0;
	margin-top: 1px;
}
.left {
	overflow-y: scroll;
	flex-grow: 4;
	width: 83%;
	border-right: 1px solid #7f89be;
	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
}

.right {
	overflow-y: auto;
	flex-grow: 4;
	width: 25%;
	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
}

.suggestion {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.card {
	margin: 0.5rem auto;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.lgsha {
	box-shadow: 0px 0px 5px #fff;
}

.darksha {
	padding: 0.35rem 0.5rem;
	border-radius: 1rem;
	box-shadow: 0px 0px 8px #0d0f22;
}

.info {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.suggestionName {
	margin: 0 0 0 0.25rem;
	font-size: 0.75rem;
	color: #7d88bb;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

.loader {
	margin: 0 auto;
	margin-bottom: 30px;
	width: 30%;
	justify-self: center;
	padding: 1rem 3rem;
	display: flex;
	justify-content: center !important;
	border-radius: 5px;
	border: 1px solid #eee;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.heading {
	margin: 2.5rem;
	display: flex;
}

.heading h1 {
	font-size: 3rem;
	font-weight: 600;
	color: #cccccc;
	cursor: pointer;
	font-family: Avenir-Bold;
}

.cards {
	margin: 0 auto;
	padding: 1rem;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.addIcon {
	font-size: 1.5rem;
	background: linear-gradient(90deg, #fc466b, #3f5efb);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

button {
	padding: 0;
}

.darkText {
	color: #d9def4;
}

.lgText {
	color: #0d0f22;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0;
		margin: 0;
	}
	.cards {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: center;
		padding-bottom: 4.5rem;
	}
}
.friendsTab{
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px 10px;
	font-size: 14px;
}

.tab {
	padding: 10px 10px;
	font-size: 14px;
}

.para {
	height: 10vh;
	margin: 0.8rem 1.4rem 1.5rem;
	color: #d9def4;
	h5{
		color: #D9DEF4;
	}
	p{
		margin-top: 0.8rem;
		font-size: 13px;
		color: white;
		opacity: 0.8;
	}
}
