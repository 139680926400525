.mainContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	z-index: 0;
	overflow-y: auto;
	/* margin-top: 2rem; */
	scrollbar-width: none;
}

.mainContainer::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.bredcrumb {
	display: flex;
	color: #ccc;
	cursor: pointer;
	font-family: Avenir-Bold;
	margin: 1rem 0 2rem 0.5rem;
}

.selectBread {
	color: var(--heading-color2);
	font-size: 1.25rem;
}

.statusDisplay {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 4vh;
	justify-content: flex-start;
}

.back {
	display: none;
}

@media screen and (max-width: 768px) {
	.mainContainer {
		width: 100%;
		z-index: 6;
		background-color: #121528;
		height: 84.9vh;
		padding: 0 1rem 0 1rem;
	}
	.back {
		display: flex;
		justify-self: flex-start;
		flex: 0;
	}

	.statusDisplay {
		justify-content: center;
		margin-top: 0;
	}
}
