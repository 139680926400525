.coverContainer {
	width: 100%;
	display: flex;
}

.coverContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 15px 15px;
	border-radius: 9px;
}

.activeMobTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.aboutBox {
	margin-bottom: 2rem;
}
.aboutHeading {
	padding: 0 15px;
	margin-bottom: 0.3rem !important;
	font-size: 12px;
}
.mobtab {
	padding: 8px;
	width: 100%;
	text-align: center;
}
.aboutSocial {
	margin: 1rem 15px;
}
.aboutContent {
	background: #030413;
	font-size: 17px;
	padding: 5px 15px;
	color: #7f89be;
}
.divider {
	border-top: 0.5px solid #2e2e48;
	margin-bottom: 2rem;
}
.mobTopBar {
	margin: 1rem 15px;
	display: flex;
	justify-content: space-between;
}
.nameCont {
	text-align: start;
	font-family: Metropolis-Medium;
}
.sidebarHeader {
	margin-left: 5rem;
}

.friendRequestContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.friendRequestContainer p {
	margin-bottom: 0;
}

.friendRequestContainer .friendRequestButtons {
	display: flex;
	justify-content: space-around;
}

.mainContainer {
	margin: auto;
	width: 100rem;
	display: flex;
	flex-direction: column;
	font-family: "Poppins";
	overflow-x: hidden;
}

.lgBg {
	background: #fff !important;
}

.darkBg {
	background: #121528 !important;
	color: #d9def4 !important;
}

.lgLinkText {
	color: #4a517e !important;
}

.darkLinkText {
	color: #7f89be !important;
}

.mainContainer::-webkit-scrollbar {
	display: none;
}

.mainContainer::-webkit-scrollbar {
	display: none;
}

.header {
	margin: 0 auto;
	padding: 2rem 0;
	width: 90%;
	display: flex;
	align-items: center;
	color: var(--heading-color2);
}

.profile {
	position: relative;
	width: 50%;
}

.content {
	width: 100%;
	/* display: block; */
	justify-content: center;
}
.content1 {
	width: 100%;
	/* display: block; */
	margin-top: 2rem;
	justify-content: center;
	/* padding-left: 2rem; */
}

.top {
	width: 100%;
	display: flex;
	align-items: center;
}

.imageBox {
	justify-content: center;
}

.topDetail {
	margin-left: 1rem;
	display: flex;
	flex-direction: column;
}

.detailsBox {
	display: flex;
	font-size: 12px;
	color: var(--secondary);
	display: flex;
}

.mobDetailsBox {
	font-size: 12px;
	margin-left: 1rem;
}

.url {
	font-size: 16px;
	color: var(--secondary);
}

.right {
	width: 90%;
	display: block;
	/* flex-wrap: wrap; */
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	margin-top: 0.8rem;
	justify-content: center;
}
.left {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	justify-content: space-around;
	/* justify-content: center; */
}

.username {
	font-size: 1.25rem;
	font-style: normal;
	line-height: 1.563rem;
	color: var(--heading-color2) !important;
}

.profession {
	display: flex;
	/* text-align: center; */
	font-style: normal;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 1.263rem;
	color: var(--secondary);
	margin-right: 15px;
}

.userDetails {
	width: 100%;
	flex-wrap: wrap;
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	justify-content: space-around;
}

.partition {
	margin: 0 auto;
	width: 90%;
	display: flex;
}

.menuList {
	display: flex;
	flex-direction: column;
	font-family: Roboto;
	color: #555;
	align-items: center;
	border-bottom: 1px solid #dfdfdf;
	padding-bottom: 1.5rem;
	/* margin-bottom: 0.8rem; */
}
.menuList1 {
	width: 102%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	/* margin-top: 0.5rem; */
	color: #555;
	/* margin-bottom: 20px; */
}

.contactBtn {
	margin-left: auto;
	display: flex;
}

.popoverbtn {
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: transparent;
}

.userScore {
	display: flex;
	justify-content: center;
	opacity: 0.8;
	font-size: 1.2rem;
}

.score {
	font-size: 1.2rem;
	margin-left: 0.4rem;
	margin-bottom: 0;
	font-family: Roboto;
	color: black;
}

.changePassword {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.option {
	display: flex;
	margin: 0 0.8rem;
	align-items: center;
	color: #fd5d5f;
}

.changePasswordText {
	font-size: 0.9rem;
	line-height: 1rem;
	margin-left: 0.4rem;
	opacity: 1;
	color: #fd5d5f;
	margin-bottom: 0;
}

.gpsIcon {
	margin: 0 0.3rem;
	height: 14px;
	width: 14px;
}

.iconCont {
	margin-right: 1rem;
	margin-bottom: 0.75rem;
	padding: 0.5rem;
	background: #7f89be;
	border-radius: 0.5rem;
}

.icon {
	width: 2rem !important;
	height: 2rem !important;
}

.urlbox {
	display: flex;
	align-items: baseline;
}

.social {
	margin: 0.7em 0;
	text-align: center;
	color: #555;
}
.heading {
	width: 100% !important;
	/* height: 20%; */
}
.data {
	width: 40.5%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}
.dataUrl {
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	flex-direction: column;
	justify-content: center;
}
.dataBio {
	display: flex;
	width: 99%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 1.19rem;
	color: var(--secondary);
}

.userContent {
	color: var(--heading-color2);
	font-size: 1rem;
	margin-bottom: 20px;
}

.mandatory {
	color: red;
	font-size: 10px;
	margin: 0;
	margin-left: auto;
	font-family: Avenir-Regular;
}

.field,
.fieldUrl {
	height: 2.5rem;
	cursor: pointer;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	background-color: white;
	outline: none !important;
	/* text-align-last: center; */
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 0.95rem;
	line-height: 1.18rem;
	color: #434343;
	width: 90%;
	padding-left: 0.45rem;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.fieldBio {
	height: 6.5rem;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	background-color: white;
	outline: none !important;
	/* text-align-last: center; */
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.18rem;
	color: #434343;
	padding: 0.7rem 0.5rem;
	width: 100%;
	position: relative;
	resize: none;
	font-family: Roboto;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.statusLoad {
	margin-left: 2rem;
}
.fieldUrl {
	padding-left: 2.5rem !important;
	font-size: 0.9em !important;
	box-shadow: none !important;
	margin-top: -0.1rem;
}

.field:disabled,
.fieldUrl:disabled {
	background-color: white;
	opacity: 0.75;
}

.buttons {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	justify-content: center;
}

.changePassContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.passwordFields {
	display: flex;
	width: 44%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}

.status {
	padding-top: 2rem;
	width: 50%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	overflow-y: auto;
}

.statusCardsDk,
.statusCardsLg {
	width: 100%;
	height: 70vh;
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
}

.statusCardsLg::-webkit-scrollbar,
.statusCardsDk::-webkit-scrollbar {
	width: 1px !important;
}
.statusCardsDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.statusCardsDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}
.statusCardsLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.statusCardsLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.cap {
	text-transform: capitalize;
}

.status1 {
	width: 100%;
}

.resultDiv {
	margin: 0 10%;
	min-height: 30vh;
}

@media only screen and (max-width: 900px) {
	.mainContainer {
		width: 100%;
		height: 100%;
		padding: 0 0;
		margin: 0 0;
	}

	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.content1 {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
} /* Styling For Mobile View */

@media only screen and (max-width: 600px) {
	.header {
		font-size: 5rem;
		left: 11rem;
		margin-top: 5px;
		padding: 0rem;
	}
	.gpsIcon {
		margin: 0 0.3rem 0 0;
	}
	.sidebarHeader {
		margin-left: 0rem;
	}
	.username,
	.profession {
		margin-bottom: 0.3rem;
		text-align: start;
	}
	.resultDiv {
		margin: 0 15px;
		min-height: 30vh;
	}

	.coverContainer {
		height: 100%;
	}

	.mainContainer {
		width: 100%;
		height: 100%;
		padding: 0 15px;
		margin: 0 0;
	}

	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.content1 {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.left {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.right {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.data1 {
		width: 100% !important;
		margin: 1rem 0 !important;
	}
	.data {
		width: 100%;
		margin-top: 1.5rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
	.data1 {
		width: 100%;
		margin-top: 1.5rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	.menuList {
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.uploadPhoto {
		width: 100%;
		margin-bottom: 1rem;
	}

	.changePassword {
		margin-top: 2rem;
	}

	.right {
		width: 100%;
		margin-top: 1rem;
	}

	.userDetails {
		width: 80%;
		margin: auto;
		margin-bottom: 2rem;
		display: block;
		max-height: 100%;
	}

	.data {
		width: 100%;
		margin-top: 1.5rem;
	}

	.passwordFields {
		width: 80%;
		margin-top: 1rem;
	}

	.status {
		flex-direction: column;
		align-items: center;
	}
}
