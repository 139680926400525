.container {
	margin: 0.5rem auto 0.75rem auto;
	margin-left: 0rem;
	width: 100%;
	padding: 1rem 0.5rem;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
	position: relative;
}

.activeChat {
	box-shadow: none;
	border: 1.5px solid rgb(0, 115, 254);
	color: white;
}

.lastMessage {
	color: #7f89be;
	font-size: 0.9em;
	margin: 5px 0 0.5rem 0;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.firstLine {
	margin: 0.25rem 0;
	display: flex;
	align-items: center;
}

.newMessage {
	margin-bottom: 0;
	margin-left: auto;
	padding: 0.1rem;
	width: 1.25rem;
	height: 1.25rem;
	line-height: 1.2rem;
	text-align: center;
	color: white;
	font-size: 0.85rem;
	/* background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%); */
	border-radius: 50%;
}

.darkGText {
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
}

.lgGText {
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
}

.heading {
	margin: 0;
	margin-left: 0.5rem;
	color: #d9def4;
	font-size: 0.9rem;
	line-height: 1.3rem;
	overflow: hidden;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-transform: capitalize;
}

.title {
	margin-top: 0.5rem;
	font-size: 1rem;
	line-height: 1.3rem;
	overflow: hidden;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #d9def4;
}

.content {
	width: 100%;
	margin-top: 1rem;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.avatars {
	display: flex;
	align-items: center;
}

.additionalmembers {
	font-size: 0.8rem;
	color: #828282;
	margin-left: 5px;
}

.date {
	margin: 0;
	color: #7f89be;
	font-size: 0.7rem;
}

.dFlex {
	display: flex;
	align-items: center;
}

.unreadMsgs {
	color: #fff;
	/* width: 100%; */
	position: relative;
	left: 75%;
	top: -50%;
	font-size: 10px;
	/* border: 1px solid ; */
	border: 1px solid;
	border-image-slice: 1;
	padding: 7px 8px 5px 8px;
	border-image-source: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	/* border-radius: 1rem; */
	background-color: #0d0f22;
	transition: ease-in-out;
}
