@import "../../../Themes/ThemeSelector.scss";

.left-sideBar,
.rightSideBar {
	@include themed() {
		background: t(backgroud-color);
	}
}

.grpname {
	@include themed() {
		color: t(heading-color2);
	}
}
