.popover {
	width: 380px;
	display: flex;
	flex-direction: column;
}

.container {
	font-family: "Poppins";
	min-height: 480px !important;
	display: flex;
	flex-direction: column;
	padding: 1rem 1.5rem 0.2rem;
	background-color: var(--dark-background);
	color: #7f89be;
	overflow-y: auto;
}

.lgbg {
	background: #fff;
}

.darkbg {
	background: #0d0f22;
}

.lgtext {
	color: #0d0f22;
}

.darktext {
	color: #d9def4;
}

.lgtextP {
	color: #4a517e;
}

.darktextP {
	color: #7f89be;
}

.header {
	display: flex;
	padding-bottom: 1rem;
	padding-left: 2rem;
	padding-right: 2rem;
	cursor: pointer;
	justify-content: space-between;
	font-family: "Poppins";
	h2 {
		font-size: 1rem;
		text-align: center;
		flex: 1;
		padding: 0.5rem 0;
		margin: 0 0;
		font-weight: 400;
	}
	.active {
		font-weight: 500;
		color: transparent;
		background-clip: text;
		background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.content {
	flex: 1;
}
.footer {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	.linkToNotificationPage {
		text-transform: none;
		font-size: 0.75rem;
		font-weight: 400;
		color: transparent;
		background-clip: text;
		background: linear-gradient(to right, #fc466b 0%, #3f5efb 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.notificationContainer {
	width: 100%;
	display: flex;
	padding: 1rem 0;
	color: var(--heading-color2);
	.notificationContent {
		flex: 1;
		font-size: 0.9rem;
	}
	.date {
		// width: 100%;
		display: flex;
		font-size: 0.9rem;
		align-items: end;
		// justify-content: flex-end;
	}
	&:hover {
		color: var(--text-primary);
	}
}

.friendRequestCard {
	padding: 1rem 0;
	border-bottom: 1px solid #dfdfdf;
	display: flex;
	align-items: center;
	.requestCont {
		margin-left: 0.2rem;
		flex: 1;
		font-size: 0.9rem;
	}

	.actions {
		display: flex;
		button {
			padding: 0 2px;
			min-width: 10px;
			&:nth-child(2) {
				margin-left: 5px;
			}
		}
	}
}

.loader {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	color: #7f89be;
	align-items: center;
	.spinner {
		margin-right: 10px;
	}
}

.empty {
	text-align: center;
	padding-top: 1rem;
	font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
	.popover {
		width: 90vw;
	}
	.container {
		padding: 10px;
	}
	.notificationContainer {
		padding: 10px 0;
	}
	.footer {
		padding-top: 10px;
	}
}
