.dropdown {
	width: 100%;
	border-radius: 10px;
	background-color: var(--backgroud-color);
	/* color: var(--secondary); */
}

.dropdownHeader {
	padding: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 8px;
}

.dropdownBody {
	padding: 10px;
	display: none;
}

.dropdownBody.open {
	display: block;
}

.dropdownItem {
	margin-left: 0.5rem;
	padding: 0.5rem;
	width: 100%;
	/* color: var(--secondary); */
	display: flex;
}

.dropdownItem:hover {
	cursor: pointer;
}

.dropdownItemDot {
	opacity: 0;
	color: #91a5be;
	transition: all 0.2s ease-in-out;
}

.dropdownItemDot.selected {
	opacity: 1;
}

.selectedDropdownItem {
	background: #121528;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
	border-radius: 8px;
	padding-left: 0.5rem;
	width: 100%;
	color: var(--heading-color2);
}

.img {
	margin-right: 0.5rem;
}

.icon {
	margin-left: 0.5rem;
	font-size: 13px;
	color: #91a5be;
	transform: rotate(0deg);
	transition: all 0.2s ease-in-out;
}

.icon.open {
	transform: rotate(90deg);
}

.selected {
	background: var(--button-gradient3);
	/* color: var(--text-primary); */
}

.label {
	padding-left: 10px;
}
