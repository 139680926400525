.darkBg {
	background: #0d0f22 !important;
}

.listButton {
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: transparent;
}

.popoverbtn:hover {
	color: var(--heading-color2);
}

.lgBg {
	z-index: 10;
	background: #fff !important;
}

.darkPText {
	color: #d9def4 !important;
}

.lgPText {
	color: #0d0f22 !important;
}

.wrapper {
	display: flex;
	flex-direction: column;
	padding: 0;
	box-sizing: border-box;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.section {
	justify-content: flex-start;
	margin: 2rem auto 1rem 0.5em;
	display: flex;
}

.time {
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	color: var(--text-primary);
}

.container {
	/* margin: auto; */
	/* width: 80rem; */
	display: flex;
	flex-direction: column;
	/* position: relative; */
	/* padding-left: 1rem; */
}

.left {
	/* width: 100%; */
	width: 93%;
	display: flex;
	flex-direction: column;
	/* position: fixed; */
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: none;
}

.left::-webkit-scrollbar {
	display: none;
}

.heading {
	padding: 1rem 0 1.5rem 0.5rem;
	display: flex;
}

.headItem {
	display: flex;
	flex-direction: row;
}

.headItem h1,
.breadActive h1 {
	margin: 0 0.5rem;
	color: var(--heading-color2);
	cursor: pointer;
	font-size: 1.5rem !important;
	padding-top: 0.15rem;
	font-weight: 500;
}

.searchContainer {
	margin: 0.5rem 1.5rem;
	border: 1px solid #7f89be;
	padding: 10px;
	border-radius: 6px;
	display: flex;
	width: 90%;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 1.1rem;
	font-weight: 400;
	flex: 1;
	padding: 0.05rem 0.15rem;
	text-align: left;
	border: none;
	border-radius: 6px;
	background: transparent;
	color: #7f89be;
}

.input::placeholder {
	color: #7f89be;
	text-align: left;
}

.head {
	display: block;
}
.breadActive {
	cursor: pointer;
	color: #fd5d5f !important;
}

.breadActive h1 {
	color: #fd5d5f !important;
}

.cards {
	justify-content: flex-start;
	display: flex;
	flex-wrap: wrap;
	width: 90%;
}

.ctaList {
	padding: 0 !important;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

.cds {
	height: 100%;
	overflow: auto;
}

@media screen and (max-width: 768px) {
	.left {
		width: 100%;
	}
	.popoverBtn {
		margin: 0.5rem 0;
		padding: 0.25rem;
		outline: none;
		border: none;
		background: transparent;
	}
	.popoverBtn1 {
		margin-top: 0.5rem;
		margin-left: 1.5rem;
		outline: none;
		background-color: white;
		border: none;
		display: flex;
		flex-direction: row;
		justify-self: left;
	}
	.popoverBtn:hover {
		color: #ff5b5b;
	}

	.wrapper {
		margin-top: 0;
		width: 100vw;
		height: 100%;
	}

	.heading {
		margin-bottom: 1rem;
		margin-top: 0.5rem;
		width: 100%;
		display: flex;
		justify-content: left;
		padding: 0 1rem;
	}

	.headItem,
	.breadActive {
		/* padding: 0 0.5rem; */
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #ccc;
	}

	.headItem h1 {
		margin: 0 0.5rem;
		font-size: 0.8rem;
		/* display: flex; */
		align-items: center;
		padding: 15px 0 0;
		line-height: 1.2rem;
	}
	.breadActive h1 {
		display: inline;
		line-height: 1.2rem;
		padding: 15px 0 0;
	}

	#lasth1 {
		border-right: none;
	}

	.cards {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 10px;
		width: 100%;
	}
	.container {
		width: 100%;
		height: 100%;
	}

	.card {
		width: 40%;
	}
}
