.wrapper {
	display: flex;
}

.wrapper:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.container {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 90.9vh;
	max-height: 55rem;
	display: flex;
	overflow: hidden;
	border: 1px #f1f1f1 solid;
}

.header {
	position: absolute;
	font-size: 12.5rem;
	font-family: Avenir-Bold;
	font-weight: 900;
	color: #ededed;
	left: 45%;
	z-index: -1;
	transform: translate(-50%);
	text-align: center;
}

.loading {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	display: flex;
	background-color: rgba(0, 0, 0, 0.25);
}

.card {
	width: 24rem;
	margin: auto;
	padding: 2rem 3rem;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: white;
	box-shadow: 0px 3px 50px #0000001a;
}

.cardSucc {
	width: 30rem;
	margin: auto;
	padding: 2rem 3rem;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: white;
	box-shadow: 0px 3px 50px #0000001a;
}

.titleCont {
	display: flex;
	justify-content: space-between;
}

.titleForm {
	margin-bottom: 2rem;
	font-size: 1.9rem;
	font-family: Avenir-Bold;
}

.titleSuccess {
	margin-bottom: 2rem;
	color: #5bffb3;
	font-size: 2rem;
	font-family: Avenir-Bold;
}

.titleFailure {
	margin-bottom: 2rem;
	color: #ff5f5f;
	font-size: 2rem;
	font-family: Avenir-Bold;
}

.subHeading {
	font-size: 1.18rem;
	font-family: Avenir-Demibold;
}

.text {
	display: flex;
	padding: 0 0.5rem 1rem;
	flex-direction: column;
}

.label {
	margin-top: 0.5rem;
	font-size: 1.1rem;
}

.inputTag {
	padding: 0.5rem;
	margin-bottom: 1rem;
	border: 1px solid #888;
	border-radius: 6px;
}

.submit {
	width: 100%;
	border: 0;
	color: white;
	margin: 2rem 0 0;
	padding: 0.5rem;
	border-radius: 8px;
	background-color: #ff5f5f;
}

.submit:disabled {
	background-color: #888;
	opacity: 0.6;
}

.buttonSuccess {
	width: 100%;
	border: 0;
	color: white;
	padding: 0.5rem;
	border-radius: 8px;
	background-color: #5bffb3;
}

.buttonFailure {
	width: 100%;
	border: 0;
	color: white;
	padding: 0.5rem;
	border-radius: 8px;
	background-color: #ff5f5f;
}

.warning {
	margin: 0;
	margin-left: auto;
	color: #ff5f5f;
	font-size: 0.7rem;
}

@media only screen and (max-width: 600px) {
	.container {
		height: 78vh;
	}

	.header {
		top: 8%;
		font-size: 4.5rem;
	}

	.cardSucc {
		width: auto;
	}
}
