.subCont {
	/* margin-bottom: 1rem; */
	margin: 10px 0px;
	/* border: 1px solid #ffffff; */
	border-radius: 8px;
	display: flex;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
	box-shadow: 0 0.315rem 1.05rem rgba(0, 0, 0, 0.068);
	padding-bottom: 10px;
}

.contentContainer {
	padding: 0.3rem 0.6rem;

	border-left: 4px solid transparent;
	background-color: white;
}

.content {
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
	padding: 1px 0 !important;
	margin-top: 1em !important;
}

.right {
	padding-left: 0 !important;
	padding-top: 1.3em !important;
	/* margin: auto 0px ; */
}

.avatarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1rem;
}
