@import "../../../Themes/ThemeSelector.scss";

.heading {
	@include themed() {
		color: t(heading-color2);
	}
}

.searchBox {
	@include themed() {
		background: t(backgroud-color);
		border: 1px solid t(secondary);
	}
}

.searchField {
	@include themed() {
		background: t(backgroud-color);
		color: t(secondary);
	}
}

.newTag {
	@include themed() {
		color: t(secondary) !important;
	}
}

.tag {
	@include themed() {
		color: t(heading-color2);
	}
}
.activetag {
	@include themed() {
		color: t(heading-color2);
	}
}
