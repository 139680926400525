.root {
	width: 30rem;
}

.head {
	margin: 2rem auto;
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.heading {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
}

.grpname {
	font-size: 1.25rem;
	color: var(--heading-color2);
}

.bio {
	margin: 1rem;
	font-size: 1rem;
	color: var(--heading-color2);
}

.headMembers {
	font-size: 0.9rem;
	color: var(--heading-color2);
}

.headerInfo {
	position: relative;
	margin: 1rem;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	z-index: 0;
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-width: none;
	font-family: "Poppins" !important;
}

.Violet_Border_Button {
	border-radius: 100rem;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	color: white;
	padding: 0.4rem 1rem;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(to right, #fc466b 0%, #3f5efb 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px#0D0F22 inset;
	margin-right: 2%;
}

.Violet_Button {
	border-radius: 100px;
	padding: 0.75rem 1.75rem;
	font-family: "Poppins", sans-serif;
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	color: white;
	border: 1px solid transparent;
	font-size: 1rem;
	padding-left: 40px;
	padding-right: 40px;
	margin-right: 2%;
}

.button_text {
	color: transparent;
	background-clip: text;
	background: -webkit-linear-gradient(left, #fc466b, #3f5efb);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
}

.top {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0 auto;
}

.createBtn {
	background: linear-gradient(225deg, #ff5b5b 0%, #fc4249 100%);
	border-radius: 6px;
	opacity: 1;
	font-size: 15px;
	color: #ffffff;

	text-transform: none;
	height: 35px;
	outline: none !important;
	margin-bottom: 1.5rem;
	width: 100%;
	font-style: normal;
	line-height: 17.9px;
	font-weight: 400;
	margin: 1.4rem auto;
}
.editPhoto {
	position: relative;
	margin-top: -2.8rem;
	margin-left: 51.5%;
	margin-bottom: 1.5rem;
}
.infoWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0.2rem auto;
	justify-content: center;
}
.container::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.item {
	display: flex;
	flex-direction: row;
	margin: 0.99rem 0;
	width: 100%;
	align-content: center;
	align-items: center;
}

.searchBox {
	margin: 0.5rem;
	padding: 0.5rem;
	width: 90%;
	display: flex;
	align-items: center;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

.members {
	margin: 1rem;
}

.ava {
	position: relative !important;
}

.icon1 {
	position: absolute;
	left: 5.5rem;
	top: 5rem;
}

.grpDanger {
	margin: 0.1rem auto;
	width: 90%;
	font-size: 0.8rem;
}

.groupInput,
.groupInputText {
	margin: 0.5rem auto;
	padding: 0.5rem;
	width: 90%;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	background: var(--dark-background);
	color: #7f89be;
	border: 0.5px solid #7f89be;
	border-radius: 0.5rem;
	outline: none;
}

.groupInputText {
	height: 10rem;
}

.globalSwitcher {
	padding: 0.7rem 0;
	align-items: center;
	display: flex;
	justify-content: center;
}

.adminTag {
	margin-left: 0.5rem;
	padding: 0.075rem 0.5rem;
	font-size: 0.75rem;
	background: linear-gradient(90deg, #00c6ff, #0072ff);
	color: #fff;
	border-radius: 0 10px 10px 0;
}

.loader {
	width: 100%;
	display: flex;
	margin: 3rem auto;
	justify-content: center;
	padding: 2rem 0;
}
.selected {
	padding-bottom: 0.2rem;
	margin: 0.1rem 0;
	cursor: pointer;
	font-size: 1.25rem;
}
.switchText {
	margin: 0.1rem 0;
	color: #7f89be;
	font-size: 1.25rem;
	cursor: pointer;
}
.membersHead {
	margin-top: 1rem;
}
.hoverBtn {
	visibility: visible;
	float: right !important;
	display: flex;
	flex-direction: column;
	margin-left: auto !important;
	/* margin-top: -0.35em; */
}
.deleteBtn {
	float: right !important;
	display: flex;
	flex-direction: column;
	margin-left: auto !important;
}
.mobileSearchBox {
	border: 1px solid #dfdfdf;
	border-radius: 6px;
	width: 98%;
	margin-top: 0.8em;
	padding: 0.2em 0.2em;
	margin-left: 0.1em;
	margin-bottom: 1em;
}
.mobileSearchBox .searchField {
	font-size: 1rem;
}
.searchField::placeholder {
	color: var(--secondary);
	font-size: 14px;
}

.searchField {
	flex: 1 1;
	border: none;
	z-index: 0;
	position: relative;
	border-radius: 4px;
	font-size: 13px;
	padding: 0.2rem;
	width: 80%;
	/* visibility: hidden; */
}
input::placeholder {
	color: var(--secondary);
	font-size: 14px;
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-content: center;
	margin: 1rem 0;
	align-items: center;
}

.buttonsCreate {
	margin: 0 auto;
	width: 90%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	margin-bottom: 2rem;
	align-items: center;
}

.hide {
	display: none;
}

@media only screen and (min-width: 524px) {
	.editText {
		/* position: sticky; */
		font-size: 1rem !important;
		right: 0;
		top: 0;
		margin-top: 6.5%;
		margin-right: 14%;
		margin-top: 0.5rem;
		float: right !important;
		color: #ff5b5b;
		align-items: right;
		cursor: pointer;
		text-decoration: underline;
	}
}
@media only screen and (max-width: 723px) {
	.editTextMob {
		display: none;
	}
}
.editText {
	font-size: 1rem !important;
	margin-left: auto;
	margin-bottom: -2.5rem;
	float: right;
}
