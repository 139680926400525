.container {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-family: "Poppins";
	/* border-bottom: 1px solid #2e2e48; */
}

.darkPText {
	color: #d9def4;
}

.lgPText {
	color: #0d0f22;
}

.darkSText {
	color: #7f89be;
}

.lgSText {
	color: #4a517e;
}

.darkGText {
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
}

.lgGText {
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
}

.heading {
	margin: 0;
	width: 100%;
	font-size: 0.75rem;
	font-weight: 400;
	font-family: "Poppins";
}

.activeChat {
	color: white;
	border-radius: 1rem;
	border: 1px solid #00c6ff;
}

.activeChat .heading {
	font-weight: 500;
}

.lastMessage {
	margin: 0;
	margin-top: 6px;
	width: 100%;
	font-size: 0.625rem;
	font-weight: 400;
	font-family: "Poppins";
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.namemessage {
	margin-left: 10px;
	display: flex;
	flex-basis: 8em;
	flex-direction: column;
	overflow: hidden;
}

.firstLine {
	display: flex;
	color: #555;
	justify-content: space-between;
}

.privatedate {
	margin: 0 auto;
}

.left {
	display: flex;
	flex: 1;
	align-items: center;
}

.newMessage {
	margin: auto 0 auto auto;
	/* margin-left: auto; */
	max-width: 1rem;
	max-height: 1rem;
	font-size: 0.625rem;
	vertical-align: middle;
	text-align: center;

	color: white;
	border-radius: 50%;
}

.content {
	width: 80%;
	max-height: 5rem;
	overflow: hidden;
	color: #555;
	margin-top: 1rem;
	font-size: 1rem;
}

.date {
	text-align: right;
	font-size: 0.5rem;
	width: max-content;
	font-weight: 400;
	font-family: "Poppins";

	margin-bottom: 0.375rem;
}
