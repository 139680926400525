.container {
	width: 78%;
	display: flex;
	position: relative;
	z-index: 1;
	font-family: Roboto;
}
.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}
.messageCont {
	transition: 500ms;
}
.highlighted {
	background-color: #ff5b5b22;
	z-index: -1;
	border-radius: 10px;
	border: #ff5b5b;
}

.loader {
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	height: 40px;
	width: 40px;
	padding: 10px;
}

.loaderContainer {
	top: 8rem;
	display: flex;
	justify-content: center;
	position: absolute;
	width: 90%;
}

.chatBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	flex: 1;
	transition: all 0.3s ease-in-out;
}

.chatBoxHalf {
	margin: 1rem;
	width: 72%;
	display: flex;
	flex-direction: column;
	transition: all 0.3s;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.chatButton {
	display: none;
}
.textIcon {
	color: #d9def4;
}
/* .textIcon svg{
  width: 30px;
} */
.textIcon,
.liked,
.fav {
	display: flex;
	padding: 0 0.8rem;
	align-items: center;
	cursor: pointer;
}

.textIcon p,
.liked p,
.fav p {
	margin: 0;
	margin-left: 1rem;
	color: #555;
}

.title {
	/* margin: 1rem 0; */
	width: 100%;
	position: relative;
	background-color: transparent;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	/* cursor: pointer; */
	display: flex;
	flex-direction: column;
	padding: 0 0.7rem;
	padding-left: 1.5rem;
}

.content {
	margin: 0;
	width: 100%;
	display: flex;
	color: #d9def4;
	font-weight: 500;
	font-style: normal;
	font-style: 1.25rem;
	font-family: "Poppins";
	padding-top: 1.125rem;
	padding-bottom: 0.313rem;
	align-items: baseline;
}

.avatarname {
	display: flex;
	flex-direction: row;
	color: #d9def4;
	font-weight: 500;
	font-style: normal;
	font-style: 1.25rem;
	font-family: "Poppins";
}

.chatsection_content {
	widows: 100%;
	display: flex;
	justify-content: space-between;
}

.avatarname .author {
	font-size: 0.75em;
	font-weight: 400;
	color: #d9def4;
	font-family: "Poppins";
	display: flex;
	align-items: center;
	margin-left: 10px;
}
.content h1 {
	font-size: 1.2rem;
	margin-right: 1rem;
}

.liked,
.fav,
.liked p,
.fav p {
	color: #ff5b5b;
}

.mainChatBoxLG,
.mainChatBoxDk {
	display: flex;
	flex-direction: column-reverse;
	padding: 0 1rem;
	overflow-y: auto;
	flex: 1;
	scrollbar-width: none;
}
*::-webkit-scrollbar {
	width: 1px !important;
}
.mainChatBoxLG::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.mainChatBoxLG::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}
.mainChatBoxDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.mainChatBoxDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

.typing {
	color: #888;
	font-size: 0.9rem;
	position: absolute;
	bottom: 4rem;
}
.titletop_breakline {
	width: 100%;
	margin: 1rem 0 !important;
}

.lghr {
	border: 1px solid #4a517e;
}

.darkhr {
	border: 1px solid #7f89be;
}

.lgtext {
	color: #0d0f22 !important;
}

.darktext {
	color: #d9def4 !important;
}

.inputBox {
	display: flex;
	width: 100%;
	margin-top: 1rem;
	bottom: 0;
}

.message {
	width: 94%;
	padding: 0.7rem 1rem;
	border: 0;
	height: 3rem;
	resize: none;
	border-radius: 6px;
	background-color: white;
	color: #555;
	box-shadow: 0px 2px 20px #00000026;
}

.joinConv {
	width: 94%;
	padding: 0.7rem 1rem;
	border: 0;
	color: #ff5b5b;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0px 2px 20px #00000026;
}

.mobChatNav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* background: #030413; */
	padding: 10px 15px;
	color: #d9def4;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 15px 15px;
	border-radius: 9px;
}

.activeMobTab {
	/* background: #4a517e; */
	background: linear-gradient(92.12deg, #00c6ff, #0072ff);
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.mobtab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

@media only screen and (max-width: 768px) {
	.container {
		width: 100%;
	}

	.chatBox {
		padding: 0;
	}

	.chatBoxHalf {
		width: 100%;
	}

	.mainChatBox {
		padding: 0 0.3rem;
	}

	.chatButton {
		display: block;
		margin-right: auto;
		padding-top: 0.5rem;
	}

	.title {
		margin-top: 0;
		padding: 0 0.5rem;
	}

	.likesContainer {
		display: flex;
		position: absolute;
		top: 10px;
		right: 12px;
	}

	.likesContainer span {
		margin-left: 1px;
		color: #878787;
		font-size: 0.8rem;
	}
	.content {
		padding: 0.5rem 0.4rem;
	}
}

.popover {
	object-fit: contain;
	background: var(--dark-background);
}

.wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	display: inline-flex;
	width: 100%;
	background: var(--dark-background);
}

.wrapper img {
	object-fit: cover;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
