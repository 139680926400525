@import "../../../Themes/ThemeSelector.scss";

.statusCard {
	@include themed() {
		background: t(backgroud-color);
	}
	// @media (max-width: 768px) {
	// 	background: #0d0f22 !important;
	// }
}

.username,
.content,
.users,
.fRight {
	@include themed() {
		color: t(heading-color2);
	}
}

.hoverBtn {
	@include themed() {
		color: t(secondary);
	}

	&:hover {
		@include themed() {
			color: t(heading-color2) !important;
		}
	}
}
