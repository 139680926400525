.root {
	padding: 1rem 2rem;
	max-height: 650px !important;
	width: 500px !important;
	overflow: hidden !important;
	font-family: "Poppins" !important;
}

.rootDark {
	background: #0d0f22 !important;
}

.rootLg {
	background: #fff !important;
}

.crossIcon {
	cursor: pointer;
}

.head {
	margin: 1rem 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.heading {
	margin: 0;
	padding: 0;
	display: flex;
	font-family: "Poppins" !important;
	align-items: center;
}

.hide {
	display: none;
}

.headinglg {
	font-size: 1.25rem;
	color: #0d0f22;
}

.headingdark {
	font-size: 1.25rem;
	color: #d9def4;
}

.textlg {
	color: #0d0f22;
}

.textdark {
	color: #d9def4;
}

.textlgP {
	color: #4a517e;
}

.textdarkP {
	color: #7f89be;
}

.grpname {
	font-size: 0.75rem;
}

.bio {
	margin: 1rem;
	font-size: 1rem;
}

.searchBox {
	margin: 0.5rem;
	padding: 0.5rem;
	width: 90%;
	display: flex;
	align-items: center;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	border: none;
}

.groupInput,
.groupInputText {
	margin: 0.5rem auto;
	padding: 0.5rem;
	width: 90%;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	background: var(--dark-background);
	color: #7f89be;
	border: 0.5px solid #7f89be;
	border-radius: 1rem;
	outline: none;
}

.groupInputText {
	height: 10rem;
}

.selected {
	padding-bottom: 0.2rem;
	margin: 0.1rem 2rem 0.1rem 0;
	cursor: pointer;
}

.switchText {
	margin: 0.1rem 2rem 0.1rem 0;
	color: #7f89be;
	cursor: pointer;
}

.membersDk,
.membersLg {
	margin: 1rem 0;
	height: 270px;
	overflow: auto;
}
.membersDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.membersDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}
.membersLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.membersLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.item {
	margin: 1rem 0;
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
}

.underline {
	margin-top: 5px;
	width: 4.75rem;
	height: 3px;
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
}

.actions {
	display: flex;
}

.buttons {
	margin: 1rem 0 1.5rem 0;
	display: flex;
}

.button {
	margin-left: 0.5rem;
}

.adminTag {
	margin-left: 0.5rem;
	padding: 0.25rem 0.5rem;
	font-size: 0.85rem;
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.tagdark {
	color: #fff;
}

.taglg {
	color: #0d0f22;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 1.25rem;
}

.header h2 {
	font-size: 1.25rem;
}

.dialog {
	padding-bottom: 30px;
	max-width: 400px !important;
	font-family: "Poppins";
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.textBox {
	margin: 1rem;
}

.textBox p {
	margin: 1rem 0;
	font-size: 14px;
}

.popoverbtn {
	border: none;
	display: flex;
	/* flex-direction: column; */
	justify-content: flex-start;
	align-items: center;
	font-size: 1rem !important;
	background: transparent;
}

.popoverbtn:hover {
	color: var(--heading-color2);
}

.popoverCap {
	font-size: 0.75rem;
	color: var(--secondary);
}

.btns {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.popcheck {
	margin: 0 auto;
	width: 95%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.d-flex {
	display: flex;
}
.items-center {
	align-items: center;
}
