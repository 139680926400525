.wrapper {
	display: flex;
	/* margin-top: 1em; */
	padding: 0;

	box-sizing: border-box;
	font-family: "Poppins";
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	width: 80rem;
	display: flex;
	height: 90.9vh;
}

.searchContainer {
	margin: 0.5rem 1.5rem 2rem 1.5rem;
	border: 1px solid #7f89be;
	padding: 10px;
	border-radius: 6px;
	display: flex;
	width: 90%;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 1.1rem;
	font-weight: 400;
	flex: 1;
	padding: 0.05rem 0.15rem;
	text-align: left;
	border: none;
	border-radius: 6px;
	background: transparent;
	color: #7f89be;
}

.left {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	overflow-y: auto;
	width: 100%;
	scrollbar-width: none;
}

.header {
	color: var(--heading-color2);
	font-weight: 500;
	padding: 0.5rem 1rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	margin: 0.5rem 0.5rem;
}

.header h2 {
	margin: 2px 0 0 0rem;
	flex: 1;
	font-size: 1.5rem;
	font-weight: 500;
}
.left::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.heading {
	margin: 2.5rem;
	display: flex;
}

.headItem {
	display: flex;
	flex-direction: row;
}

.headItem h1 {
	margin: 0 0.5rem;
	font-family: Roboto;
	font-size: 1.5rem;
	color: #cccccc;
	cursor: pointer;
}

.headItem h1:hover {
	color: #fd5d5f !important;
}

.breadActive {
	color: #fd5d5f !important;
}

.cards {
	display: flex;
	justify-content: flex-start;
	margin: 1rem 1rem;
	flex-wrap: wrap;
	width: 100%;
}
.section {
	display: flex;
	justify-content: flex-start;
	margin: 1rem 1.7rem;
}
.time {
	font-weight: normal;
	font-size: 1.25rem;
	color: var(--text-primary);
}

@media screen and (max-width: 768px) {
	.left {
		width: 100%;
	}
	.wrapper {
		margin-top: 0;
		height: 100%;
	}

	.container {
		height: 100%;
	}

	.heading {
		margin: 2.5rem 0;

		width: 100%;
		display: flex;
		justify-content: space-evenly;
		border-bottom: 1px solid #ccc;
	}

	.headItem {
		padding: 0 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #ccc;
	}

	.headItem h1 {
		margin: 0 0.5rem;
		font-size: 1.1rem;
	}

	#lasth1 {
		border-right: none;
	}

	.cards {
		/* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem; */
		display: flex;
		width: 100%;
		margin-left: 0;
		flex-direction: row;
		justify-content: center;
		padding: 10px 10px 15px 10px;
		margin: 0 0;
	}

	.card {
		width: 40%;
	}

	.section {
		display: flex;
		justify-content: flex-start;
		margin: 13px 17px 0 17px;
	}

	.content {
		height: 100%;
		overflow: auto;
		margin-top: 1rem;
		margin-bottom: 3rem !important;
	}
}
