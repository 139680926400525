.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Metropolis-Medium;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.content ol li {
	margin-bottom: 1rem;
}
.content ul li {
	margin-bottom: 1rem;
}

.container {
	margin: 0 auto;
	width: 100rem;
	position: relative;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	/* border: 1px solid #ccc; */
}

.cont {
	margin: 6rem 7rem;
}

.heading {
	margin: 2rem 0;
	font-family: Avenir-Bold;
	font-size: 3rem;
}

.subHeading {
	font-family: Avenir-Bold;
	font-size: 1.2rem;
}

.content {
	margin-bottom: 2rem;
	font-size: 1rem;
	color: #828282;
	text-align: justify;
}

.blueBox {
	width: 15rem;
	height: 25rem;
	position: absolute;
	bottom: 14rem;
	right: -10rem;
	background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
	transform: rotateZ(-20deg);
	border-radius: 3rem;
	z-index: 1;
}

@media screen and (max-width: 768px) {
	.cont {
		margin: 7rem 1rem;
	}

	.blueBox {
		width: 15rem;
		height: 25rem;
		position: absolute;
		bottom: 34rem;
		right: -10rem;
		background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
		transform: rotateZ(-20deg);
		border-radius: 3rem;
		z-index: -1;
	}

	.yellowBox {
		width: 25rem;
		height: 40rem;
		position: absolute;
		top: 7rem;
		left: -18rem;
		background: linear-gradient(
			180.57deg,
			#ffd166 0.49%,
			rgba(255, 130, 102, 0) 147.16%
		);
		border-radius: 3rem;
		transform: rotateZ(35deg);
		z-index: -1;
	}
}
