.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	font-family: "Poppins";
}

.popover {
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
}

.lgText {
	color: #0d0f22;
}

.darkText {
	color: #d9def4;
}

.globalSwitcher {
	padding: 0.7rem 0;
	align-items: center;
	display: flex;
	justify-content: center;
	font-weight: 400;
	font-size: 1.25rem;
	.selected {
		padding: 0 1rem;
		cursor: pointer;
	}
	.switchText {
		padding: 0 1rem;
		color: #4a517e;
		cursor: pointer;
	}
}
.caption {
	font-size: 0.75rem;
	font-weight: 400;
	margin-bottom: 1rem;
}

.caption2 {
	color: #7f89be;
	font-size: 0.7rem;
}

.searchLine {
	align-items: center;
}
.searchContainer {
	flex: 1;
	border: 1px solid #dfdfdf;
	display: flex;
	align-items: center;
	position: relative;
	padding: 0.5rem;
	border-radius: 5px;
	margin-right: 5px;
	input {
		flex: 1;
		font-weight: 400;
		border: none;
		color: #b2b2b2;
		font-family: Roboto;
		font-size: 0.75rem;
		position: relative;
		background: var(--backgroud-color);
		&:focus {
			border-color: #ff6c6c;
		}
	}
}

.searchIcon {
	margin: auto 0;
}

.saveButton {
	text-transform: capitalize !important;
	font-weight: 400 !important;
}

.darkBg {
	background-color: #0d0f22;
	color: #fff;
	margin-top: 0.25rem;
	top: 2.5rem;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	width: 100%;
	z-index: 1;
	left: 0;
	position: absolute;
	flex-direction: column;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	.option {
		padding: 0.5rem 0;
		display: flex;
		cursor: pointer;
		align-items: center;
		border-radius: 4px;
		// border-bottom: 1px solid #eeeeee;
		font-family: Roboto;
		font-size: 0.8rem;
		&:hover {
			background-color: #0a0a14;
		}

		.optionText {
			flex: 1;
			margin-left: 0.5rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.secondaryText {
				font-size: 0.7rem;
				color: #999999;
				margin-left: 5px;
			}
		}
	}
}

.lightBg {
	background-color: #fff;
	color: #000;
	margin-top: 0.25rem;
	top: 2.5rem;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	width: 100%;
	z-index: 1;
	left: 0;
	position: absolute;
	flex-direction: column;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	.option {
		padding: 0.5rem 0;
		display: flex;
		cursor: pointer;
		align-items: center;
		border-radius: 4px;
		// border-bottom: 1px solid #eeeeee;
		font-family: Roboto;
		font-size: 0.8rem;
		&:hover {
			background-color: #eeeeee;
		}

		.optionText {
			flex: 1;
			margin-left: 0.5rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.secondaryText {
				font-size: 0.7rem;
				color: #999999;
				margin-left: 5px;
			}
		}
	}
}

.selectorContainer {
	margin-top: 0.25rem;
	// background-color: white;
	top: 2.5rem;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	width: 100%;
	z-index: 1;
	left: 0;
	position: absolute;
	flex-direction: column;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	.option {
		padding: 0.5rem 0;
		display: flex;
		cursor: pointer;
		align-items: center;
		border-radius: 4px;
		// border-bottom: 1px solid #eeeeee;
		font-family: Roboto;
		font-size: 0.8rem;
		&:hover {
			background-color: #eeeeee;
		}

		.optionText {
			flex: 1;
			margin-left: 0.5rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.secondaryText {
				font-size: 0.7rem;
				color: #999999;
				margin-left: 5px;
			}
		}
	}
}

.selectedLocationsContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-bottom: 1rem;
	// min-height: 110px;
	.selectedLoc {
		border-bottom: 1px solid #dfdfdf;
		display: flex;
		padding: 0.5rem 0;
		align-items: center;
		font-size: 0.75rem;
		p {
			margin: 0;
			flex: 1;
		}
		text-transform: capitalize;
		&:last-child {
			border-bottom-color: transparent;
		}
	}
}

.myLocationBox {
	display: flex;
	margin-top: 1.5rem;
	cursor: pointer;
}

.myLocationIcon {
	margin-top: 0px;
}

.myLocation {
	text-transform: none;
	font-size: 0.75rem;
	font-weight: 400;
	color: transparent;
	background-clip: text;
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.searchLoader {
	display: flex;
	padding: 1rem 0;
	align-items: center;
	justify-content: center;
	.loader {
		background-color: white;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
		border-radius: 50%;
		height: 40px;
		width: 40px;
		padding: 5px;
	}
}

.notfound {
	display: flex;
	justify-content: center;
	color: #aaa;
	padding: 1rem;
	font-size: 0.8rem;
}

.footer {
	display: flex;
	margin-bottom: 0.7rem;
	justify-content: center;
}

.saveButton {
	flex: 1;
}

.Save_Button {
	border-radius: 100rem;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	color: white;
	padding: 0.75rem 1.75rem;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 2px transparent;
	background-image: var(--button-gradient2);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px#0D0F22 inset;
	padding-left: 20%;
	padding-right: 20%;
	margin: 0 auto;
}

.Save_button_text {
	color: transparent;
	background-clip: text;
	background: -webkit-linear-gradient(var(--text-gradient1));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
}

.locationButton {
	flex: 1;
	border-radius: 5px;
	margin: 0.25rem;
	border: 2px solid #5b6cff;
	background-color: white;
	color: #5b6cff;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	font-weight: 500;
	justify-content: center;
	line-height: 0.8rem;
	.locationIcon {
		font-size: 1rem;
		color: #5b6cff;
		margin-right: 7px;
	}
}

@media only screen and (max-width: 600px) {
}
