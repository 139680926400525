.foldersContainer {
	width: 100%;
	height: 100%;
	padding: 1rem 0 0 0.5rem;
	border-right: 1px solid #dfdfdf;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;

	position: relative;
}
.foldersContainer::-webkit-scrollbar {
	display: none;
}
.header {
	padding: 01rem;
	display: flex;
	justify-content: center;
}

.folders {
	display: flex;
	/* min-height: 170px; */
	justify-content: flex-start;
	flex-direction: column;
}

.foldersPlaceHolder {
	margin: 10px;
	width: 95%;
	height: 50px;
	flex-shrink: 0;
	border-radius: 2px;
}

.noFolders {
	flex: 1;
	width: 100%;
	display: flex;
	font-family: Avenir-Regular;
	color: #999;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.noFolders p {
	font-size: 0.9rem;
	margin-top: 10px;
}

@media screen and (max-width: 768px) {
	.foldersContainer {
		padding: 10px 0;
	}
}
