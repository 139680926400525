.link_colour {
	color: #e42a5b;
}

.replyCont {
	margin: 0.25rem 0;
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
	background: #7f89be;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
	max-height: 8rem;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}

.dateCont {
	display: flex;
	flex-direction: column;
}
.likes {
	margin: 0 0.6rem;
	display: flex;
	align-items: center;
	flex: 0;
	cursor: pointer;
}

.date {
	margin: 0 0 0.25rem auto;
	justify-content: space-between;
	align-self: center;
	display: flex;
	align-items: center;
	color: #7f89be;
	font-family: "Poppins";
	font-weight: 400;
	font-style: normal;
	font-size: 0.625rem;
	margin-top: 5px;
}
.myDate {
	margin: 0 0 0.25rem auto;
	/* justify-content: flex-end; */
	/* align-self: flex-end; */
	/* display: flex; */
	align-items: center;
	color: #d9def4;
	font-family: "Poppins";
	font-weight: 400;
	font-style: normal;
	font-size: 0.625rem;
	/* margin-top: 5px; */
}

.meta {
	margin: 0.5rem 0;
	background: #7f89be;
	border: 1px solid #7f89be;
	display: flex;
	color: #d9def4;
	transition: 0.4s;
	cursor: pointer;
	padding: 0.5rem 0;
	border-radius: 1rem;
	align-items: center;
	/* height: 6rem; */
	height: max-content;
}
.meta:hover {
	color: black;
	background: #7f89be;
}

.metaMedia {
	max-width: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.5rem;
}
.metaMedia img {
	min-height: 40px;
	max-height: 4rem;
	max-width: 80%;
	object-fit: fill;
}

.metaContent {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.metaContent h2 {
	font-size: 1.1rem;

	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
.metaContent p {
	font-size: 0.8rem;
	margin: 0;
}

.message {
	margin: 0.25rem 0.5rem 0.25rem 0;
	color: #ffff;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	font-family: Poppins;
}

.restrictHeight {
	max-height: 200px;
	overflow: hidden;
}

.imageCont {
	margin: 0 !important;
	width: 100%;
	max-width: 25rem;
}

.imgOverlay {
	width: 25rem;
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	border-radius: 4px;
	top: 0.5rem;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.img {
	margin: 0;
	max-width: 25rem;
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	border-radius: 1rem;
}

.downloadSegment {
	display: flex;
	color: #333;
	max-width: 400px;
	margin-top: 10px;
	border-radius: 5px;
	align-items: center;
	padding: 5px;
	cursor: pointer;
	border: none;
	transition: 300ms;
}

.downloadSegment p {
	font-size: 0.8rem;
	margin-bottom: 0;
	margin-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.audioMessage {
	display: flex;
	width: 22rem;
	margin: 0.4rem 0 0 0;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.downloadSegment {
		max-width: 100%;
	}
}
