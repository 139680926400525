.popover {
	display: flex;
	flex-direction: column;
	min-width: 6rem;
}

.divHr{
	margin: 1rem 0;
	width: 100%;
	margin-left: 0 !important;
}

.option {
	margin: 0.3rem 0;
	font-size: 1rem;
	font-weight: 500;
	outline: none;
	border: none;
	width: 100%;
	text-align: left;
	background: transparent;
}
.MuiPaper-root {
	background: red !important;
	box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);
	border-radius: 8px;
	box-sizing: border-box;
	border: 1px solid #0d0f22;
}

.MuiPaper-rounded {
	border-radius: 8px !important;
}

.show_save_list {
	display: flex;
	flex-direction: row;
	margin-bottom: 13px;
	cursor: pointer;
}

.show_report_list {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.show_user_list {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.save_label {
	font-family: "Poppins";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	color: #d9def4;
}
.add_label {
	font-family: "Poppins";
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	color: #7f89be;
}
.report_label {
	font-family: "Poppins";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	color: #d9def4;
}
.mark_label {
	font-family: "Poppins";
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	color: #7f89be;
}
.popoverBtn {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 50%;
}

.popoverBtn:hover {
	background: #dfdfdf;
}

.liked {
	margin: 0;
	color: #ff5b5b;
}

.text {
	margin: 0;
}
