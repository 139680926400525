@import "../../Themes/ThemeSelector.scss";

.MuiDialog-root-dark {
	background: #121528 !important;
}

.rootlg {
	background: #fff !important;
}

.dark-heading {
	color: #d9def4 !important;
}

.lg-heading {
	color: #0d0f22 !important;
}

.selected-lg {
	color: #0d0f22;
}

.text-lg {
	color: #4a517e;
}

.selected-dark {
	color: #fff;
}

.text-dark {
	color: #7f89be;
}
