.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.group {
	position: absolute;
	top: 9%;
	left: 1rem;
	width: 18%;
	height: 100%;
	z-index: 10;
}

.grplg {
	background: #fff;
}

.grpdark {
	background: #111527;
}

.grpContainer {
	margin: 0;
	width: 16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Poppins" !important;
}

.groupName {
	display: flex;
	align-items: center;
}

.groupName p {
	margin: 0;
}

.grpname {
	margin-left: 0.5rem;
	font-size: 1rem;
}

.coverContainer {
	display: flex;
}

.coverContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mainContainer {
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 0;
	max-height: 100vh;
	width: 100rem;
	overflow: hidden;
	border-bottom: 0;
}

.header {
	margin: 2rem auto 0 1rem;
	width: 98%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	font-family: "Poppins";
	position: relative;
}
/* 
.group{
  position: absolute;
  top: 9%;
  left: 1rem;
  width: 18%;
  height: 100%;
  background: #111527;
  z-index: 10;
  overflow-y: auto;
} */

.heading {
	margin: 2rem 1rem 1rem 1rem;
	color: var(--heading-color2);
}
.heading h2 {
	color: var(--heading-color2);
	/* font-size: 1.5rem; */
}

.mobContent {
	margin: 1rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	font-family: "Poppins" !important;
	width: 90%;
	scrollbar-width: none;
	height: 100vh;
}

.contentDk,
.contentLg {
	margin: 1% 1rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	font-family: "Poppins" !important;
	width: 90%;
	scrollbar-width: none;
}

.contentDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.contentDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

.contentLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.contentLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.cards {
	margin: 1rem auto;
	margin-left: 1rem;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: flex-start;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: none;
}

.cards::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 1rem;
	font-weight: 400;
	flex: 1;
	padding: 0.05rem 0.15rem;
	text-align: left;
	border: none;
	border-radius: 6px;
	background: transparent;
	color: #d8def3;
	width: 100%;
}
.input::placeholder {
	color: #b2b2b2;
	text-align: left;
}

.Violet_Border_Button {
	border-radius: 100rem;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	color: white;
	padding: 0.75rem 1.75rem;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(to right, #fc466b 0%, #3f5efb 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	padding-left: 40px;
	padding-right: 40px;
	margin-right: 2%;
}

.btn {
	margin-right: 2rem;
}

.Violet_Button {
	border-radius: 100px;
	padding: 0.75rem 1.75rem;
	font-family: "Poppins", sans-serif;
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	color: white;
	border: 1px solid transparent;
	font-size: 1rem;
	padding-left: 40px;
	padding-right: 40px;
	margin-right: 2%;
}

.button_text {
	color: transparent;
	background-clip: text;
	background: -webkit-linear-gradient(left, #fc466b, #3f5efb);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
}

.button_text2 {
	margin: 0;
}

.status {
	width: 100%;
	overflow: auto;
	scrollbar-width: none;
}

.resultBox {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.status::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

/* .grpContainer{
  margin: 0;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupName{
  display: flex;
  align-items: center;
}

.groupName p {
  margin: 0;
}

.grpname{
  margin-left: .5rem;
  font-size: 1rem;
  color: #D9DEF4;
} */

.searchContainer {
	margin: 1rem 1rem 2rem 1rem;
	border: 1px solid #7f89be;
	max-width: 90%;
	padding: 5px 5px 5px 1rem;
	border-radius: 6px;
	display: flex;
	align-items: center;
	color: #d8def3;
}

.links {
	margin: 1rem 1rem;
	width: 90%;
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 1.09rem;
	line-height: 1.29rem;
	justify-content: flex-start;
}

.link,
.selected {
	margin-right: 2.5rem;
	cursor: pointer;
	color: rgba(85, 85, 85, 1);
	/* text-decoration: underline; */
}

.link:hover,
.selected {
	color: #fd5d5f;
	padding-bottom: 0.2rem;
	border-bottom: 1px solid #fd5d5f;
}

.mainSetCont {
	margin: 0 1rem;
	margin-bottom: 2rem;
	width: 90%;
	border-radius: 0.5rem;
}

.recentSearchContainer {
	margin: 1rem 0;
}

.recentSearch {
	margin: 10px 1rem 0 1rem;
	padding: 10px 0;
	font-size: 1rem;
	border-bottom: 1px solid #2e2e48;
	display: flex;
	align-items: center;
}

.hide {
	display: none;
}

.user {
	overflow-y: auto;
	scrollbar-width: none;
	margin-top: 2rem;
	padding: 1rem 2rem;
	margin-left: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	width: 90%;
	flex-direction: row;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	/* justify-content:space-evenly; */
}

.user::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.resultuserDiv {
	width: 75%;
	cursor: pointer;
}
.resultuserDiv1 {
	/* flex: 45%; 
  flex-basis: 45% ; */
	width: 46%;
	position: relative;
	left: 0;
	flex-direction: column;
}
/* .resultuserDiv:nth-of-type(even) {
  margin-left: 4rem;
  padding-left: 2.5rem;
} */

.policyHeader {
	padding: 0 1rem;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.msgResult {
	margin: 0 auto;
	width: 95%;
	display: flex;
	flex-wrap: wrap;
}

.msg {
	width: 32%;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px 0;
	border-radius: 9px;
	width: 100%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px 10px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 10px 10px;
	width: 100%;
	text-align: center;
}

@media only screen and (max-width: 768px) {
	.input {
		font-size: 1rem;
	}
	.searchContainer {
		margin: 0.8rem 0rem 0.5rem 0rem;
		border: 1px solid #dfdfdf;
		max-width: 90%;
		padding: 10px;
		border-radius: 6px;
		display: flex;
	}
}
