.coverContainer {
	display: flex;
}

.heading {
	width: 100% !important;
}

.lgbg {
	background: #fff !important;
}

.darkbg {
	background: #121528 !important;
}

.darkPText {
	color: #d9def4 !important;
}

.lgPText {
	color: #0d0f22 !important;
}

.mainContainer {
	margin: auto;
	padding: 0 2rem 2rem 2rem;
	width: 100%;
	height: 92.9vh;
	overflow-y: auto;
	/* border: 1px #f1f1f1 solid; */
	border-bottom: 0;
}

.mainContainer::-webkit-scrollbar {
	display: none;
}

.locationSelector {
	position: relative;
	width: 100%;
}

.contentEdit {
	width: 100%;
	/* display: block; */
	display: flex;
	flex-direction: column;
	/* flex: 1; */
	/* margin-top: 2rem; */
	align-content: center;
	justify-content: center;
}

.content {
	width: 100%;
	/* display: block; */
	justify-content: center;
}

.content1 {
	width: 100%;
	/* display: block; */
	display: flex;
	margin-top: 2rem;
	justify-content: center;
	/* padding-left: 2rem; */
}

.top {
	width: 95%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}

.imageBox {
	display: flex;
	margin: 0 auto;
	margin-top: 2rem;
	width: 100%;
	justify-content: center;
}

.imageBoxEdit {
	display: flex;
	margin: 2rem 0;
	margin-bottom: 3rem;
	margin-left: 1rem;
}

.left {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	justify-content: space-around;
	/* justify-content: center; */
}

.leftEdit {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	/* height: 100%; */
	justify-content: center;
}

.right {
	width: 50%;
	display: block;
	/* flex-wrap: wrap; */
	padding-left: 2.5rem;
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	margin-top: 0.8rem;
	justify-content: center;
}
.rightEdit {
	width: 100%;
	display: block;
	/* padding-left: 1rem; */
	/* height: 100%; */
	justify-content: center;
}
.hide {
	display: none;
}
.username {
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 1.31rem;
	line-height: 1.563rem;
	margin-top: 0.9rem;
	color: #ff6c5c !important;
}
.profession {
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 0.89rem;
	line-height: 1.263rem;
	margin-top: -0.7rem;
	color: #000000;
}
.userDetails {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	/* margin: 3rem 6rem 0rem 6rem; */
	height: 100%;
	justify-content: space-around;
}
.icon {
	position: relative;
	top: 45px;
	left: 10px;
	z-index: 3;
	width: 1rem;
	/* text-align: center; */
}

.flex {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.icon1 {
	position: sticky;
	margin-bottom: -1.8em;
	margin-top: 0.5em;
	margin-left: 0.45em;
	z-index: 3;
	width: 1rem;
	/* text-align: center; */
}
.cancelIcon,
.cancelIcon2 {
	width: 1.99em;
	position: relative;
	display: inline !important;
	padding: 0.1em 0.1em;
	border: 1px solid #cccccc;
	border-radius: 4px;
	background-color: #fafafa;
	color: black;
}

.cancelIcon2 {
	margin-left: 0.2em;
}
.checkIcon {
	margin-top: 0.2em;
	margin-left: 70%;
}
.social {
	margin: 1em 0;
	text-align: center;
	color: #555;
}
.locationSelectorBox {
	/* height: 100px; */
	width: 100%;
	z-index: 1;
	background-color: white;
	/* border: 1px solid red; */
	margin-top: 5px;
	position: absolute;
}

.option {
	display: flex;
	padding: 5px;
	align-items: center;
}

.option:hover {
	background-color: #ededed;
	cursor: pointer;
}

.option .optionText {
	flex: 1;
	margin-left: 1px;
}

.option .optionText .primaryText {
	font-size: 0.9rem;
}

.primaryText {
	color: var(--text-primary);
}

.option .optionText .secondaryText {
	font-size: 0.7rem;
	margin-left: 3px;
	color: #999;
}

.option:hover {
	color: black;
}

.label {
	color: var(--secondary) !important;
}
.header {
	padding: 1rem;
	display: flex;
	align-items: center;
}

.headerText {
	/* margin: 0rem 1rem; */
	color: var(--heading-color2);
}

.headerText2 {
	color: var(--heading-color2);
	margin: 5% 0;
}

.headerText3 {
	color: var(--heading-color2);
	margin: 2% 0;
}

.headerText h2,
.headerText2 h2,
.headerText3 h2 {
	font-size: 1.25rem;
}

.userBox {
	margin-left: 2%;
	margin-top: 2%;
}

.userBox h2 {
	color: var(--heading-color2);
	font-size: 1.25rem;
}

.userBox p {
	color: var(--secondary);
	font-size: 0.875rem;
}

.menuList {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	color: #555;
}

.uploadPhoto {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: -2rem;
	margin-top: -2.5rem;
	z-index: 8;
	position: relative;
	width: fit-content;
	opacity: 1;
	cursor: pointer;
}

.edit {
	display: flex;
	margin-top: 2rem;
	justify-content: right;
	color: #ff5b5b;
	align-items: right;
	cursor: pointer;
}
.hide {
	display: none;
}
.editUrl {
	display: flex;
	margin-top: 1rem;
	justify-content: center;
	color: #ff5b5b;
	align-items: center;
	cursor: pointer;
}
@media only screen and (min-width: 524px) {
	.editText {
		/* position: sticky; */
		font-size: 1rem !important;
		right: 0;
		top: 0;
		margin-top: 6.5%;
		margin-right: 14%;
		margin-top: -10rem;
		float: right;
		color: #ff5b5b;
		align-items: right;
		cursor: pointer;
		text-decoration: underline;
	}
}
@media only screen and (max-width: 723px) {
	.editTextMob {
		display: none;
	}
}
.editText {
	font-size: 1rem !important;
	margin-left: 0.4rem;
	margin-bottom: 0;
	float: right;
}
.changePassword {
	display: flex;
	margin-top: 3rem;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.changePasswordText {
	font-size: 1rem !important;
	margin-left: 0.4rem;
	opacity: 0.8;
	margin-bottom: 0;
}

.data {
	display: flex;
	width: 40%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}

.dataEdit {
	display: flex;
	width: 70%;
	margin-bottom: 2rem;
	flex-direction: row;
}

.optionspop {
	margin-left: -0.5rem;
	padding: 0;
	width: 78%;
	/* background: rebeccapurple; */
}

.optionsPopOverEdit {
	margin-bottom: 2rem;
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.industryInputlg {
	margin-bottom: 2rem;
	width: 70%;
	color: #4a517e;
	border: 1px solid #4a517e;
	border-radius: 0.5rem;
}

.industryInputdark {
	margin-bottom: 2rem;
	width: 70%;
	color: #7f89be;

	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.industryInputTextdark {
	color: red !important;
}

.industryInputTextdark {
	color: blue !important;
}

.dataBioEdit {
	display: flex;
	width: 70%;
	padding-bottom: 3rem;
	border-bottom: 1px solid #dadada;
	margin-bottom: 2rem;
	flex-direction: row;
}
.dataUrlEdit {
	display: flex;
	width: 100%;
	flex-direction: column;
	/*justify-content: center; */
}
.dataUrlContainer {
	display: flex;
	width: 70%;
	flex-direction: row;
}
.data1 {
	display: flex;
	width: 95%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}
.dataBio {
	display: flex;
	width: 90%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}

.label,
.labelEdit {
	padding-left: 0.4em;
	font-style: normal;
	font-weight: normal;
	font-size: 0.9rem;
	line-height: 1.19rem;
	color: var(--secondary);
}
.labelUrlEdit {
	width: 14rem;
	font-size: 1rem !important;
}
.labelEdit {
	width: 15rem;
	font-size: 1rem !important;
}

.labelBio,
.labelEdit,
.labelUrlEdit {
	padding-left: 0.4em;
	margin-top: 1em;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 0.9rem;
	line-height: 1.18rem;
	color: #434343;
}

.mandatory {
	color: red;
	font-size: 16px;
	margin-left: 0.05rem;
	margin-top: -0.8rem;
	font-family: Roboto;
}

.option {
	background: var(--dark-background);
}

.field,
.fieldUrl,
.fieldEdit,
.fieldDate,
.fieldUrlEdit,
.fieldDateEdit,
.fieldUrlEditWeb {
	height: 2.5rem;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	background-color: white;
	outline: none !important;
	/* text-align-last: center; */
	font-style: normal;
	font-weight: normal;
	font-size: 0.95rem;
	line-height: 1.18rem;
	color: #434343;
	width: 100%;
	padding-left: 0.45rem;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.fieldEdit,
.fieldDateEdit,
.fieldUrlEdit,
.fieldBioEdit {
	border: 1px solid #7f89be !important;
	box-sizing: border-box;
	border-radius: 8px !important;
	box-shadow: none !important;
	width: 100%;
	margin-top: 0.35rem;
	background: transparent;
	height: 58px;
	padding: 20px;
}

.fieldEdit::placeholder {
	color: #7f89be;
}

.fieldUrl {
	padding-left: 2.5rem !important;
	font-size: 0.9em !important;
	box-shadow: none !important;
	margin-top: -0.1rem;
	cursor: pointer;
}

.fieldUrlEdit {
	margin-top: -0.1rem;
	width: 100%;
	padding-left: 4.5rem !important;
	font-size: 0.9em !important;
}

.fieldUrlEditWeb {
	border: 1px solid #dfdfdf !important;
	box-sizing: border-box;
	border-radius: 2px !important;
	box-shadow: none !important;
	width: 100%;
	margin-top: -0.5rem;
	padding-left: 2.2rem !important;
	font-size: 0.9em !important;
}
.fieldDate {
	padding-left: 0.5em;
}
.fieldBio,
.fieldBioEdit {
	height: 6.5rem;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	background-color: white;
	outline: none !important;
	/* text-align-last: center; */
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.18rem;
	color: #434343;
	padding: 0.7rem 0.5rem;
	width: 100%;
	position: relative;
	resize: none;
	font-family: Roboto;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.field:disabled,
.fieldDate:disabled,
.fieldUrl:disabled {
	background-color: white;
	opacity: 0.75;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #121528 inset !important;
 -webkit-text-fill-color: #D !important;

} */
.buttons {
	width: 65%;
	display: flex;
	margin-top: 3rem;
	justify-content: space-between;
}
.buttons1 {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	padding-left: 4rem;
	margin-bottom: 1.5rem;
	/* justify-content: cent */
}

.cancelButton {
	margin-right: 20px;
}

.changePassContainer {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	flex-direction: column;
	/* margin-left: 10rem; */
}

.passwordFields {
	display: flex;
	width: 65%;
	margin-bottom: 1rem;
	flex-direction: column;
	justify-content: center;
}

/* Styling For Mobile View */

.mobNav {
	width: 100%;
	display: flex;
	align-items: center;
	background: #030413;
	padding: 5px 10px;
}
.avatarStyle {
	display: flex;
	align-items: center;
	margin: 20px 15px;
}

@media only screen and (max-width: 768px) {
	.contentEdit {
		width: 100%;
	}
	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.content1 {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.top {
		width: 100%;
		margin: 0 auto;
		justify-content: center;
		/* flex-direction:row; */
	}
	.topEdit {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		width: 100%;
		margin: 0 auto;
		justify-content: center;
		flex-direction: row;
	}
	.left {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.right {
		width: 100%;
		display: flex;
		margin-left: 0;
		padding-left: 2rem;
		flex-direction: column;
	}
	.buttons {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 3rem;
		justify-content: space-between;
		padding-bottom: 70px;
	}
	.imageBoxEdit {
		margin: 2rem 0;
		padding: 0;
	}
	.rightEdit {
		margin: 0;
		padding: 0;
	}
	.leftEdit {
		margin: 0;
		padding: 0;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	}
	.headerText2 {
		color: var(--heading-color2);
		margin: 2rem 10px;
	}
	.headerText3 {
		color: var(--heading-color2);
		margin: 2rem 10px 0 10px;
	}
	.dataEdit {
		width: 100%;
		margin: 1rem 0;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	}
	.firstDataEdit {
		width: 100%;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	}
	.dataBioEdit {
		width: 100%;
		margin: 1rem 0;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	}
	.data1 {
		width: 100% !important;
		margin: 1rem 0 !important;
	}
	/* .fieldUrlEdit{
    width: 14rem;
  }
  .fieldUrl{
    width: 18rem;
  } */
	.imageBoxEdit {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.userBox {
		margin-left: 0;
		margin-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.firstDataEdit {
		width: 100%;
	}
	.header {
		font-size: 5rem;
		left: 11rem;
		margin-top: 5px;
	}
	.changePassContainer {
		margin: 0rem 0;
		width: 100%;
		padding: 0;
	}
	.passwordFields {
		margin: 10px 0;
		padding: 0;
		width: 100%;
	}
	.coverContainer {
		height: 100%;
	}

	.mainContainer {
		width: 100%;
		height: 90vh;
		overflow: scroll;
		padding: 0 1rem;
	}

	.content {
		display: block;
	}

	.top {
		width: 100%;
	}

	.menuList {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.uploadPhoto {
		/* width: 100%; */
		/* margin-bottom: 1rem; */
	}

	.edit,
	.editUrl {
		margin: 0;
	}

	.editText {
		display: none;
	}
	.editBtns {
		margin-top: -5rem;
		padding: 0 0.3rem;
		margin-bottom: -4rem;
		cursor: pointer;
	}
	.editTextMob {
		/* position: sticky; */
		font-size: 0.7rem !important;
		right: 0;
		display: inline-block;
		top: 0;
		/* margin-top: 6.5%;
    margin-right: 14%; */
		margin-top: 0.5rem;
		float: right;
		color: #ff5b5b;
		align-items: right;
		cursor: pointer;
		text-decoration: underline;
	}
	.changePassword {
		margin-top: 0;
	}

	.changePasswordText {
		display: none;
	}

	.right {
		width: 100%;
		margin-top: 1rem;
	}
	.leftEdit {
		width: 100%;
		display: block;
		padding-left: 0;
		height: 100%;
		justify-content: center;
	}
	.fieldUrlEdit {
		width: 100%;
	}
	.dataUrlContainer {
		width: 100%;
		flex-direction: column;
	}
	.rightEdit {
		width: 100%;
		display: block;
		padding-left: 0;
		height: 100%;
		justify-content: center;
	}
	.cards {
		margin: 2rem auto;
		width: 95%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.header h2 {
		margin: 1px 0 0 0.8rem;
		flex: 1;
		font-size: 1.2rem;
		font-weight: 500;
	}
	.userDetails {
		width: 80%;
		margin: auto;
		margin-bottom: 2rem;
		display: block;
		max-height: 100%;
	}

	.data {
		width: 100%;
		margin-top: 1.5rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
	.data1 {
		width: 100%;
		margin-top: 1.5rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
}

.dateView input[type="date"] {
	background: transparent;
	color: white;
}

.dateView input[type="date"]::-webkit-calendar-picker-indicator {
	filter: invert(100%);
}
