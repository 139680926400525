.logo {
	position: absolute;
	width: 5rem;

	margin: 2.5rem 6rem;
	// width: 100vw;
	font-size: 3rem;
	z-index: 1;
	img {
		width: 100%;
	}

	a {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.logo {
		width: 8rem;
		margin: 0 0rem;
	}
}
