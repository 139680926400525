.lgText {
	color: #0d0f22 !important;
}

.darkText {
	color: #d9def4 !important;
}

.toggleContainer {
	margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
	.mainContainer {
		margin: 0 0;
		padding: 0;
		width: 100%;
		height: 90.9vh;
		overflow-y: auto;
		border-bottom: 0;
	}
	.toggleText {
		flex: 90%;
		flex-basis: 90%;
		justify-content: flex-start;
		margin-left: 20px;
	}
	.select {
		margin-top: 0.1rem;
		/* margin-left: 0.5rem; */
		/* margin-left: 2rem; */
		padding-left: 0.5rem;
		justify-content: right;
	}
	.toggleContainer {
		width: 100%;
		justify-content: flex-start;
	}

	.bottom {
		justify-content: flex-start;
	}
}

@media only screen and (min-width: 800px) {
	.mainContainer {
		margin-left: 0rem;
	}
	hr {
		margin-left: 3.5rem !important;
	}
}

.header {
	margin: 1.05rem 1.2rem 1.5rem 0.5rem;
	color: var(--heading-color2);
	width: 100%;
	display: flex;
}

.header h2 {
	margin: 2px 0 0 0.4rem;
	flex: 1;
	font-size: 1.25rem;
	font-weight: 500;
}
.top {
	margin-top: 3rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}
.toggleText {
	flex: 70%;
	flex-basis: 70%;
	justify-content: flex-start;
	color: var(--heading-color2);
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.9rem;
	margin-left: 20px;
}
.toggle {
	flex: 30%;
	flex-basis: 30%;
	/* margin-left: 2rem; */
	justify-content: right;
}
.select {
	margin-top: 0.1rem;
	/* margin-left: 0.5rem; */
	/* margin-left: 2rem; */
	padding-left: 0.5rem;
	justify-content: right;
}
.bottom {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}
.toggleContainer {
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}
