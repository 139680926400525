.__floater __floater__open {
	border: 1px solid red;
}

.tourTooltip {
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 1rem 1.5rem;
	width: 400px;
	.navContainer {
		display: flex;
		.close {
			position: absolute;
			top: 20px;
			right: 20px;
		}
		.navItem {
			flex: 1;
			.step {
				float: left;
				height: 2px;
				background-color: #c4c4c4;
				width: 8px;
				margin: 10px 4px;
			}
			.active-step {
				background-color: black;
			}
		}
	}
	.content {
		.subhead {
			font-size: 0.9rem;
		}
	}

	.navigation {
		display: flex;
		justify-content: flex-end;
		button {
			border: none;
			outline: none;
			background-color: white;
			margin: 0 0.5rem;
		}
		.done {
			width: 3.5rem;
			padding: 7px 10px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.tourTooltip {
		width: 300px;
	}
}
