@import "../auth.scss";
@import "../../../Themes/ThemeSelector.scss";

.logoM {
	margin: 0;
	width: 5rem;
	margin-bottom: 2rem;
}

.header {
	margin: 0 auto;
	margin-top: 10rem;
	width: 40rem;
	text-align: center !important;
	h1 {
		font-size: 4.5rem;
		@include themed() {
			color: t(heading-color1);
		}
	}

	p {
		@include themed() {
			color: t(secondary);
		}
	}
}

.form {
	margin: 2rem 0;
	padding: 2rem 1rem;
	display: flex;
	border-radius: 1rem;
	@include themed() {
		box-shadow: 0px 0px 120px 0px t(dark-background);
	}
	.left {
		padding: 1rem 0;
		width: 33%;
		h1 {
			width: 80%;
			font-size: 3.5rem;
			font-weight: 200;
			@include themed() {
				color: t(heading-color1);
			}
			line-height: 5rem;
		}
		.launchImg {
			margin: 3rem 0;
		}
	}
	.right {
		padding: 1rem 0;
		width: 67%;
		p,
		h5 {
			font-weight: 400;
			@include themed() {
				color: t(heading-color1);
			}
		}
		.login {
			margin-bottom: 0.5rem;
			display: flex;
			align-items: center;
			p {
				margin-left: 0.5rem;
				margin-bottom: 0;
			}
		}
		.underline {
			width: 50px;
			height: 3px;
			@include themed() {
				background: t(button-gradient1);
			}
		}
		.login-form {
			width: 100%;
			margin-top: 4rem;
			overflow-y: auto;
		}
		form {
			margin-top: 1rem;
		}
		.inputDiv {
			margin-top: 0.5rem;
			display: flex;
			width: 100%;
			align-items: flex-end;
			.input {
				width: 46%;
			}
			p {
				@include themed() {
					color: t(heading-color1);
				}
				margin-left: 1.5rem;
				font-size: 0.75rem;
			}
			.login-btn {
				padding: 0.6rem;
				width: 46%;
				display: flex;
				align-items: center;
				justify-content: center;
				@include themed() {
					background: t(button-gradient1);
				}
				@include themed() {
					color: t(heading-color1);
				}
				border: none;
				border-radius: 5rem;
			}
			.forget {
				font-size: 0.75rem;
				@include themed() {
					background: t(button-gradient1);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			.link {
				margin-bottom: 0;
			}
		}
	}
}

.orLine {
	margin: 5rem 0 !important;
	width: 100%;
	@include themed() {
		border-top: 1px solid t(secondary);
	}
	position: relative;
	&:before {
		content: "or continue with";
		position: absolute;
		@include themed() {
			background-color: t(backgroud-color);
		}
		top: -10px;
		color: #7f89be;
		font-size: 0.8rem;
		padding: 0 5%;
		left: 39%;
	}
}

.gradienth1 {
	font-weight: 600;
	font-size: 3.5rem;
	@include themed() {
		background: t(button-gradient1);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.button {
	padding: 0.5rem 1rem;
	margin-bottom: 1.5rem;
	width: 46%;
	display: flex;
	align-items: center;
	background: transparent;
	border-radius: 0.25rem;
	@include themed() {
		border: 1px solid t(secondary);
	}
	p {
		margin-left: 2rem;
		margin-bottom: 0;
		@include themed() {
			color: t(secondary) !important;
		}
		font-size: 0.75rem;
		font-weight: 400;
	}
}

.outLinks {
	@include themed() {
		color: t(secondary) !important;
	}
	p,
	a,
	.forget {
		@include themed() {
			color: t(secondary);
		}
	}
}

/* Styling For Mobile View */

@media screen and (max-width: 768px) {
	.login-form-container {
		padding: 1rem !important;
		width: 100%;
	}

	.otpInput {
		width: 2.5rem !important;
		height: 2.5rem !important;
		margin: 0.125rem !important;
	}

	.header {
		display: none;
	}

	.form {
		margin: 4rem 0;
		padding: 1rem;
		.left {
			display: none;
		}
		.right {
			padding: 1rem 0;
			width: 100%;
			p,
			h5 {
				font-size: 1rem;
				font-weight: 400;
				@include themed() {
					color: t(heading-color1);
				}
			}
			.login {
				margin-bottom: 0.5rem;
				display: flex;
				align-items: center;
				p {
					margin-left: 0.5rem;
					margin-bottom: 0;
				}
			}
			.underline {
				width: 50px;
				height: 3px;
				@include themed() {
					background: t(button-gradient1);
				}
			}
			.login-form {
				width: 100%;
				margin-top: 2rem;
			}
			form {
				margin-top: 1rem;
			}
			.inputDiv {
				margin-top: 0.5rem;
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: flex-start;
				.input {
					width: 100%;
				}
				p {
					@include themed() {
						color: t(heading-color1);
					}
					margin-left: 0;
					font-size: 0.75rem;
				}
				.login-btn {
					padding: 0.6rem;
					width: 100%;
					@include themed() {
						background: t(button-gradient1);
					}
					@include themed() {
						color: t(heading-color1);
					}
					border: none;
					border-radius: 5rem;
				}
				.forget {
					align-self: flex-end;
				}
				.link {
					width: 100%;
					margin: 1rem 0;
					text-align: center;
				}
			}
		}
	}

	.orLine {
		margin: 3rem auto 4rem auto;
		&:before {
			left: 30%;
		}
	}

	.button {
		width: 100%;
		p {
			margin-left: 2rem;
			margin-bottom: 0;
			@include themed() {
				color: t(secondary) !important;
			}
			font-size: 0.85rem !important;
			font-weight: 400;
		}
	}
}

.inputCode {
	margin: 1rem 0;
	width: auto;
}

.otpInput {
	width: 3rem;
	height: 3rem;
	margin: 0.125rem;
	font-size: 2rem;
	text-align: center;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
}
