@import "../auth.scss";

@import "../../../Themes/ThemeSelector.scss";

.auth-page-Container {
	.container {
		// border: 1px solid red;
		padding-top: 3rem;
		.reset-password-form-container {
			.headerN {
				margin: 0 auto;
				margin-top: 5rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				text-align: center !important;
				h1 {
					font-size: 4.5rem;
					@include themed() {
						color: t(heading-color1);
					}
				}

				p {
					align-self: center;
					// width: 40rem;
					@include themed() {
						color: t(secondary);
					}
				}
			}

			.signup-form {
				padding: 0;
				form {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				.login-btn {
					padding-top: 0.5rem;
					font-size: 0.9rem;
					button {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.loader {
						height: 100%;
						margin-left: 10px;
					}
					.MuiCircularProgress-colorPrimary {
						color: white !important;
					}
				}

				.disclaimer {
					color: #8692a6;
					font-size: 0.8rem;
				}

				.no-account {
					padding: 1rem 0;
					p {
						text-align: center;
						font-weight: 400;
						font-size: 0.9rem;
						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.auth-page-Container {
		.container {
			padding-top: 5rem;
			.reset-password-form-container {
				.headerN {
					margin-top: 0px;
					h1 {
						font-size: 2.5rem;
						margin-bottom: 15px;
						@include themed() {
							color: t(heading-color1);
						}
					}
				}
				padding: 1rem 0;
				.signup-form {
					padding: 0 0.6rem;
				}
			}
		}
	}
}

/* Styling For Mobile View */
