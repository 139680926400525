.homeContainer {
	display: flex;
}

.homeContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.center {
	margin: auto;
	padding: 0;
	width: 100rem;

	height: 91.3vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	// border: 1px solid green;
}

.callArea {
	display: flex;
	flex: 1;
	// flex-direction: row;
}

.videoContainer {
	background-color: #434343;
	display: flex;
	flex-direction: column;
	margin: 10px 5px 0;
	flex: 1;
	position: relative;
	color: white;
}
.header {
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 10;
	.back {
		color: white !important;
		text-transform: none !important;
		display: flex;
		align-items: center;
		svg {
			font-size: 2rem;
		}
		span {
			flex: 1;
			font-weight: 300;
			font-size: 1rem;
		}
	}
	.expandButton {
		color: white;
	}
}
.footer {
	display: flex;
	justify-content: space-between;
	.info {
		.username {
			margin: 0 1rem;
		}
		display: flex;
		align-items: center;
	}
	padding: 0 1rem 1rem 0;

	position: relative;
	z-index: 10;
	.own {
		justify-content: flex-end;
		margin-right: 1rem;
	}
}

.videoOff {
	z-index: -1;
}

video {
	position: absolute;
	// border: 1px solid red;
	width: 100%;
	height: 100%;
}

.avatarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}
.fullScreenContainer {
	background-color: #434343;
	display: flex;
	flex-direction: column;
	margin: 10px 5px 0;
	flex: 1;
	z-index: 0;
	position: relative;
	color: white;
}
.smallContainer {
	background-color: #434343;
	z-index: 5;
	display: flex;
	flex-direction: column;
	margin: 10px 5px 0;
	flex: 1;
	position: absolute;
	right: 0;
	bottom: 4.4rem;
	width: 20rem;
	height: 15rem;
	color: white;
}

@media only screen and (max-width: 600px) {
	.callArea {
		display: flex;
		flex-direction: column;
	}
	.smallContainer {
		width: 10rem;
		height: 8rem;
	}
}
