.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Metropolis-Medium;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: 0 auto;
	width: 100rem;
	position: relative;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	/* border: 1px solid #ccc; */
}

.cont {
	margin: 6rem 7rem;
}

.heading {
	margin: 3rem 0;
	font-family: Avenir-Bold;
	font-size: 3rem;
}

.content {
	margin-bottom: 2rem;
	font-size: 1rem;
	color: #828282;
	padding-right: 1.5rem;
	font-weight: normal;
}

.valueCont {
	margin: 5rem 0;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.dotsContainer {
	display: flex;
	width: fit-content;
	justify-self: center;
	margin: 0 auto;
	cursor: pointer;
}

.dotsContainer .dot {
	width: 10px;
	height: 10px;
	margin: 10px;
	border-radius: 5px;
	border: 3px solid #999999;
}
.dotsContainer .dot:hover {
	border-color: #333333;
	background-color: #333333;
}

.dotsContainer .activeDot {
	border-color: #333333;
	background-color: #333333;
}

.useCards {
	flex-basis: 33.33%;
}

.value {
	padding-right: 3.3rem;
	font-size: 1.5rem;
	min-height: 50px;
	color: #2856c8;
}

.contact {
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact > p {
	font-family: "DM Sans";
	font-weight: 500;
	font-size: 2rem;
}

.contactBtn {
	margin: 1rem;
	padding: 0.5rem 2rem;
	outline: none;
	border: none;
	color: #fff;
	background: linear-gradient(45deg, #ff5b5b 0%, #dba3db 100%);
	border-radius: 10px;
}

.blueBox {
	width: 15rem;
	height: 25rem;
	position: absolute;
	top: 73rem;
	right: -10rem;
	background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
	transform: rotateZ(-20deg);
	border-radius: 3rem;
	z-index: 10;
}

.carouselContainer {
	width: 100%;
	height: 28rem;
	margin-top: 10px;
	position: relative;
}

.beforeBtn,
.afterBtn {
	position: absolute;
	top: 13rem;
	border: 0;
	height: 3.5rem;
	width: 3.5rem;
	opacity: 0.3;
	border-radius: 50%;
	font-size: 2rem;
	color: white;
	background-color: #333333;
	z-index: 1;
}

.beforeBtn:hover {
	opacity: 0.7;
}
.afterBtn:hover {
	opacity: 0.7;
}

.afterBtn {
	right: 0;
}

.carouselItems {
	position: absolute;
	display: flex;
	padding-left: 16vw;
	z-index: 0;
	transition: 500ms;
}
.carouselItem {
	width: 50rem;
	height: 27.1rem;
	/* border: 1px solid red; */
	margin-right: 3rem;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.carouselLabel {
	/* padding: 20px; */
	padding-top: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
}

.carouselLabel p {
	font-size: 18px;
	font-weight: lighter;
	text-align: center;
	max-width: 60%;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.carouselLabel h4 {
	font-family: "DM Sans";
}
.carouselItemImg {
	height: 27rem;
	width: 50rem;
}
@media screen and (max-width: 768px) {
	.cont {
		margin: 7rem 2rem;
	}

	.valueCont {
		margin: 3rem 0;
		display: flex;
		flex-direction: column;
	}

	.yellowBox {
		width: 25rem;
		height: 40rem;
		position: absolute;
		top: 10rem;
		left: -20rem;
		background: linear-gradient(
			180.57deg,
			#ffd166 0.49%,
			rgba(255, 130, 102, 0) 147.16%
		);
		border-radius: 3rem;
		transform: rotateZ(25deg);
		z-index: -1;
	}

	.blueBox {
		width: 15rem;
		height: 25rem;
		position: absolute;
		top: 47rem;
		right: -10rem;
		background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
		transform: rotateZ(-26deg);
		border-radius: 3rem;
		z-index: -1;
	}

	.heading {
		font-size: 2rem;
		margin-bottom: 3rem !important;
	}
	.value {
		min-height: fit-content;
	}

	.carouselItems {
		padding-left: 0;
	}

	.carouselContainer {
		height: 12rem;
	}

	.beforeBtn,
	.afterBtn {
		top: 5rem;
		height: 20px;
		width: 20px;
		height: 2rem;
		width: 2rem;
		font-size: 1rem;
	}

	.carouselItem {
		width: 20rem;
		height: 11.1rem;
		/* border: 1px solid red; */
	}
	.carouselItemImg {
		height: 11rem;
		width: 100%;
	}
	.carouselLabel h4 {
		text-align: center;
	}
	.carouselLabel p {
		max-width: 100%;
	}
	.contact > p {
		font-size: 1.3rem;
		margin-bottom: 0;
	}
}
