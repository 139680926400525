.container {
	margin: 1rem 0;
	padding: 1rem 1rem 1rem 0;
}

.buttonHeader {
	display: flex;
	align-items: center;
	transition: 250ms;
	cursor: pointer;
}

.buttonHeader .title {
	margin-bottom: 0;
	flex: 1;
	color: #d9def4;
	font-size: 1.25rem;
	font-weight: 500;
}

.mediaPreviewContainer {
	margin: 2rem 0;
	display: flex;
	overflow-x: auto;
}

.mediaPreviewContainer::-webkit-scrollbar {
	display: none;
}

.mediaPreviewContainer::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 5px grey;  */
	border-radius: 1px;
}

.mediaPreviewContainer::-webkit-scrollbar-thumb {
	background: red;
	/* border-radius: 10px; */
}

.mediaPreviewContainer::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

.mediaLoaderContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	overflow-x: auto;
}

.mediaPreview {
	width: 8rem;
	height: 8rem;
	margin-right: 0.5rem;
	background-color: #f3f3f3;
	display: flex;
	border-radius: 0.5rem;
}

.mediaPreview img {
	width: 8rem;
	height: 8rem;
	object-fit: cover;
	overflow: hidden;
	border-radius: 0.5rem;
}

.noMediaTypography {
	color: #888;
	font-size: 0.8rem;
}

@media only screen and (max-width: 768px) {
	.container {
		margin: 0;
		padding: 0;
	}
}
