.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-y: hidden;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	width: 80rem;
	display: flex;
	height: 91.5vh;
	flex-direction: column;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
}

.container::-webkit-scrollbar {
	display: none;
}
.header {
	color: var(--heading-color2);
	padding: 1rem 0.5rem 0.5rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
}

.header h2 {
	margin: 2px 0 0 0rem;
	flex: 1;
	font-size: 1.5rem;
	font-weight: 500;
}
.searchContainer {
	margin: 1.5rem 2rem 1rem 0rem;
	border: 1px solid #7f89be;
	max-width: 90%;
	padding: 16px 10px;
	border-radius: 6px;
	display: flex;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 0.9rem;
	font-weight: 400;
	flex: 1;
	color: #7f89be;
	background: transparent;
}

.input::placeholder {
	color: #7f89be;
	text-align: left;
}

.form {
	margin: 8rem 0 0 6rem;
	width: 100%;
}

.label {
	margin-right: 1rem;
	font-size: 1.1rem;
	color: rgba(34, 34, 34, 1);
}

.input {
	text-align: left;
	border: none;
	border-radius: 6px;
	/* box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15); */
}

.links {
	margin: 2rem 0 0rem 3rem;
	width: 90%;
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 1.05rem;
	line-height: 1.18rem;
	justify-content: flex-start;
}

.link,
.selected {
	margin-right: 4.5rem;
	cursor: pointer;
	color: rgba(85, 85, 85, 1);
	/* text-decoration: underline; */
}

.link:hover,
.selected {
	color: #fd5d5f;
	padding-bottom: 0.2rem;
	border-bottom: 1px solid #fd5d5f;
}

.cards {
	margin: 2rem 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.sectionHeader {
	width: 95%;
	margin: 0 auto;
	font-size: 1.3rem;
	padding-left: 1rem;
	color: #ff5b5b;
}
.alphabeticCards {
	display: flex;
	flex-direction: column;
	width: 90%;
	margin: 1.8rem 0;
}

.card {
	margin: 0.5rem 1rem;
	padding: 0.5rem;
	width: 90%;
	display: flex;
	/* box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05); */
	padding-bottom: 20px;
	border-bottom: 1px solid #4a517e;
}
.card:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
}
.profileLink {
	display: flex;
	flex: 1;
}

.acceptButton {
	margin-right: 10px;
}

.declineText {
	margin: 10px !important;
	color: var(--secondary);
	cursor: pointer;
}

.pendingCard {
	margin: 0.5rem 1rem;
	padding: 0.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 10%;
	align-items: center;
	background: transparent;
	border-bottom: 1px solid #4a517e;
	padding-bottom: 20px;
	border-radius: 2px;
}
.pendingCard:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
}
.pendingRequestProfileLink {
	display: flex;
}

.btnCont {
	display: flex;
	margin-left: 1rem;
}

.card > a {
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* .card:nth-child(3n) {
  background: rgba(237, 237, 237, 1);
} */

.green,
.red {
	width: 0.75rem;
	height: 0.75rem;
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	z-index: 1;
}

.red {
	background: #ff6c5c;
}
.green {
	background: #5bffb3;
}

.imgCont {
	margin-right: 1rem;
}

.cardImg,
.pendingImg {
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
}

.cardText {
	margin: 0.5rem 0.8rem 0.5rem 0;
	padding-top: 0.25rem;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 0.9rem;
	line-height: 1rem;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cardText1 {
	margin: 0.2rem 0.8rem 0rem 0;
	font-style: normal;
	font-weight: normal;

	line-height: 1rem;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cardBtn {
	color: rgba(34, 34, 34, 1);
	border: 0px transparent;
}
.avatarSent {
	margin-top: 0.5rem !important;
}
.btnAccept,
.btnReject {
	margin: 0 0.25rem;
	padding: 0.25rem 0.9rem;
	font-size: 0.88rem;
	color: #fff;
	background: #ff5b5b;
	border: 0px transparent;
	border-radius: 5px;
	/* box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15); */
}

.btnReject {
	color: #ff5b5b;
	background: #ffffff;
	border: 1px solid #ff5b5b;
	/* box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15); */
}

.popover {
	display: flex;
	flex-direction: column;
}

.popoverBtn {
	margin: 0.5rem 0;
	padding: 0.25rem;
	outline: none;
	border: none;
	background: transparent;
}

.popoverBtn:hover {
	color: #ff5b5b;
}

.rootDark {
	background: #0d0f22 !important;
}

.rootLg {
	background: #fff !important;
}

.networkTabs {
	background: #0d0f22;
	border: 1px solid #2e2e48;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 5px;
	color: #d9def4;
	font-size: 17px;
}

.mobNav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #030413;
	/* padding: 7px; */
	padding: 0.7rem 1rem;
}

.headerText h2,
.headerText2 h2,
.headerText3 h2 {
	font-size: 17px;
}

.darkPText {
	color: #d9def4 !important;
}

.lgPText {
	color: #0d0f22 !important;
}

@media screen and (max-width: 768px) {
	.emptyState {
		/* border: 1px solid red; */
		position: relative;
	}
	.popoverBtn1 {
		margin-top: 0.5rem;
		margin-left: 1.5rem;
		outline: none;
		background-color: white;
		border: none;
		display: flex;
		flex-direction: row;
		justify-self: left;
	}
	.container {
		height: 100%;
	}
	.wrapper {
		height: 100%;
	}

	.watermark {
		top: 3rem;
		left: 2rem;
		font-size: 5rem;
	}

	.form {
		margin: 5rem auto 2rem auto;
		padding: 1rem;
		display: flex;
		align-items: baseline;
		font-size: 1.1rem;
	}

	.links {
		margin: 1rem 0 1rem -0.2rem;
		width: 100%;
		font-size: 0.9rem;
	}

	.link,
	.selected {
		margin: 0 auto;
		font-size: 0.9rem;
		padding-bottom: 0.1rem;
	}

	.cards {
		/* margin: 0 auto; */
		/* margin-left: 1rem; */
		margin-bottom: 0;
		width: 100%;
		display: flex;
		align-content: center;
		/* flex: 1; */
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: auto;
		margin-top: 1rem;
	}
	.alphabeticCards {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 1rem 10px;
		margin: 0;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.card {
		margin: 1rem auto;
		width: 90%;
		border-bottom: none;
		padding: 0;
	}

	.sectionHeader {
		width: 100%;
		padding: 0;
		display: flex;
		justify-content: flex-start;

		margin-left: 2.3rem;
	}
	.pendingCard {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		border-bottom: none;
		padding-bottom: 0.5rem;
		width: auto;
		margin-right: 5%;
		margin: 0.7rem 1rem;
	}
	.pendingRequestProfileLink {
		width: auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		align-items: center;
	}

	.btnCont {
		margin-top: 0;
		width: auto;
		margin-left: 5px;
	}
	.avatarSent {
		margin: 0 0;
	}
	.btnCont button {
		flex: 1;
	}
	.searchContainer {
		margin: 0 5px 0 10px;
		padding: 0.4rem 0.5rem;
		width: 100%;
	}
}
