.contHide {
	transform: translateX(100%);
	display: none;
}

.content {
	flex: 1;
}

.backContainer {
	height: 30px;
}

.contShow {
	padding: 1rem;
	width: 25%;
	display: flex;
	position: absolute;
	flex-direction: column;
	height: 100%;
	right: 0;
	z-index: 200;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(0);
	transition: transform 0.3s ease-out;
	overflow-y: auto;
	scrollbar-width: none;
	overflow-x: hidden;
}

.contShow::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.topContainer {
	margin: 24px 0;
	color: #fff;
	font-size: 20px;
}

.header {
	margin-top: 2rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.imageCont {
	display: flex;
	justify-content: space-around;
}

.image {
	width: 100%;
}

.nameCont {
	/* margin-top: 1rem;  */
	/* padding: 1rem; */
	text-align: center;
	font-family: Metropolis-Medium;
}

.title {
	font-size: 0.9rem;
	cursor: pointer;
}

.date {
	width: max-content;
	margin: 1rem 0 0 auto;
	font-size: 0.8rem;
	color: #888;
	font-family: Avenir-Demibold;
}

.memberCont {
	display: flex;
	align-items: center;
	margin: 1rem 0 0;
	cursor: pointer;
}

.username {
	font-size: 1rem;
	margin: 0 1rem 0;
	color: #7f89be;
}

.leave {
	padding: 1rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	flex: 1;
	font-family: Avenir-Demibold;
}

.leave:hover {
	background: #f4f2f2;
}

.leaveTitle {
	font-size: 1rem;
	color: #ff5d5d;
	margin: 0;
	margin: auto;
}

.heading {
	padding: 0.5rem;
	display: flex;
	align-items: center;
}

.heading .title {
	padding: 1rem 0;
	display: flex;
	align-items: center;
}

.heading .title h1 {
	margin: 0;
	margin-left: 1rem;
}

.selected {
	font-size: 1.5rem;
	color: #d9def4;
	font-weight: 500;
	cursor: pointer;
}

.btns {
	display: flex;
}

.closeBtn {
	color: #d9def4;
	cursor: pointer;
}

.no {
	margin: 0.5rem;
	font-size: 1rem;
	text-align: center;
}

.mediaCont {
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.mediaCont::-webkit-scrollbar {
	display: none;
}

.media {
	margin: 0.2rem auto;
	min-width: 8.5rem;
	width: 8.5rem;
	height: 8rem;
	display: flex;
	justify-content: center;
	background: #4a517e;
	transition: all 0.3s ease-in-out;
	outline: none;
	border: none;
	overflow: hidden;
	border-radius: 0.5rem;
}

.media img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
}

.profileCont {
	display: flex;
	width: 100%;
}

.profileCont a {
	width: 100%;
}

.profileBtn {
	margin: 10px 0;
	padding: 0.5rem 1rem;
	font-family: Avenir-Demibold;
	font-size: 1rem;
	border: none;
	width: 100%;
	font-weight: 500;
	background: linear-gradient(45deg, #ff5b5b 0%, #dba3db 100%);
	color: #fff;
	transition: all 0.2s ease-in-out;
}

.profileBtn:hover {
	opacity: 0.85;
	color: #fff;
}

.searchContainer {
	margin: 1rem 0;
	padding: 5px 0.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	transition: 200ms cubic-bezier(0.56, 0.04, 0.33, 1.26);
	border: 1px solid #7f89be;
	box-sizing: border-box;
	border-radius: 8px;
}

.searchContainer .searchIcon {
	color: #7f89be;
}

.search {
	font-size: 0.75rem;
	border: 0;
	width: 90%;
	flex: 1;
	font-family: "Poppins";
	color: #7f89be;
	font-weight: 400;
	background-color: transparent;
}
.search:disabled {
	background-color: transparent;
}

.search::placeholder {
	color: #7f89be;
}

.dataUrl {
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	flex-direction: column;
	justify-content: center;
}
.urlbox {
	display: flex;
	align-items: baseline;
}
.iconCont {
	margin-right: 1rem;
	margin-bottom: 0.75rem;
	padding: 0.5rem;
	background: #d9def4;
	border-radius: 0.5rem;
}

@media only screen and (max-width: 768px) {
	.contShow {
		width: 100%;
		background-color: transparent;
		padding: 0 1.5rem;
		display: flex;
		position: relative;
		flex-direction: column;
		height: 100%;
		right: 0;
		z-index: 200;
		box-shadow: none;
		transition: transform 0.3s ease-out;
		overflow-y: auto;
		scrollbar-width: none;
		overflow-x: hidden;
	}

	.topContainer {
		margin: 0;
	}

	.media {
		margin: 5px;
		min-width: 6rem;
		width: 6rem !important;
		height: 6rem;
	}

	.nameCont {
		text-align: start;
	}

	.username {
		font-size: 1rem;
		margin: 0;
		/* color: #fff; */
		color: #7f89be;
	}
}
