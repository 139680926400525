.card {
	padding: 0.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	background: transparent;
	padding-bottom: 20px;
	border-bottom: 1px solid #4a517e;
}
@media screen and (min-width: 1268px) {
	.card:nth-child(even) {
		/* margin-left: 5rem; */
	}
}

.profileLink {
	display: flex;
	flex: 1;
	max-width: 90%;
	align-items: center;
}
.avatar {
	width: 3.125rem;
	height: 3.125rem;
}
.cardText {
	margin: 0;
	margin-top: -0.2rem;
	font-size: 1rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	color: #434343;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 14px;
}
.cardText1 {
	margin: auto 0;
	padding-top: 0.1rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	line-height: 1.1rem;
	color: #434343;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 14px;
}
.popover {
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.popoverBtn {
	margin: 0.5rem 0;
	padding: 0.25rem;
	outline: none;
	border: none;
	background: transparent;
}

.deleteButton {
	margin-right: 10px;
}

.card:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
}

.card:hover .popoverBtn {
	visibility: visible;
	/* margin-top: 0.35em; */
}
.popover:hover .popoverBtn {
	visibility: visible;
	/* margin-top: 0.35em; */
}

@media screen and (max-width: 768px) {
	.card {
		/* margin: 1rem auto; */
		width: 100%;
		padding-bottom: 0;
		margin: 0.5rem;
		border-bottom: none;
		/* background: #030413; */
	}
	.popoverBtn {
		margin: 0.5rem 0;
		padding: 0.25rem;
		outline: none;
		border: none;
		background: transparent;
		visibility: visible;
	}
}
