/* .container{
    display: flex;
    flex-direction: column;
    align-content: center;
} */
.homeContainer {
	display: flex;
	position: relative;
	height: 100%;
}
.sidebarContainer {
	display: flex;
	flex-direction: column;
	width: 18%;
	height: 100%;
	background: var(--backgroud-color);
	box-shadow: -3px 0px 30px #0000000d;
	opacity: 1;
	overflow: none;
	scrollbar-width: none;
}
.grpContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-content: center;
	margin-top: 1.8rem;
	margin-left: -0.2rem;
	width: 105%;
}
.grpname {
	width: 37%;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.1rem;
	/* identical to box height */
	color: #2f2f2f;
	margin: 0 1.7rem 0 0.4rem;
}

@media only screen and (min-width: 1200px) {
	.homeContainer {
		padding-left: 0.7em;
	}
}

.homeContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.center {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 91.5vh;
	display: flex;
	overflow: hidden;
	position: relative;
}

.noStatusContainer {
	width: 67% !important;
	justify-content: center;
}

@media only screen and (max-width: 600px) {
	.center {
		height: 100%;
	}
}

.head {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 23px;
	line-height: 26px;
	margin-bottom: 0.3rem;
	margin-top: 0.3rem;
	color: #2f2f2f;
}
.container {
	width: 67%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* align-self: center; */
	padding: 1.9rem 0rem 2rem 1.5rem;
	z-index: 0;
	overflow-y: auto;
	scrollbar-width: none;
	background-color: white;
}
.top {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}
.bio {
	margin-top: 1.9rem;
}
.createBtn {
	background: linear-gradient(225deg, #ff5b5b 0%, #fc4249 100%);
	border-radius: 6px;
	opacity: 1;
	font-size: 15px;
	color: #ffffff;
	font-family: Roboto;
	text-transform: none;
	height: 35px;
	outline: none !important;
	margin-bottom: 1.5rem;
	width: 100%;
	font-style: normal;
	line-height: 17.9px;
	font-weight: 400;
	margin: 1.4rem auto;
}
.editPhoto {
	position: relative;
	margin-top: -2.5rem;
	margin-left: 52.5%;
	margin-bottom: 1.5rem;
}
.infoWrapper {
	width: 50%;
	display: flex;
	flex-direction: column;
	/* flex: 1; */
	margin: 0.9rem auto;
	justify-content: center;
}
.container::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.item {
	display: flex;
	flex-direction: row;
	margin: 0.99rem 0;
	align-content: center;
	align-items: center;
}

.loader {
	width: 100%;
	margin: 6rem auto;
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}
