.modal {
	padding: 2rem;
	font-family: Avenir-Regular;
}

.success,
.failure {
	margin-bottom: 2rem;
	font-family: Avenir-Bold;
	font-size: 2.1rem;
	color: #5bffb3;
}

.successCheck,
.failedIcon {
	margin: 1rem 2rem 2rem 0;
	color: #5bffb3;
}

.failure,
.failedIcon {
	color: #ff5b5b;
}

.title {
	font-family: Avenir-DemiBold;
	font-size: 1.5rem;
	font-weight: 500;
}

.successBtn,
.faliureBtn {
	margin: 1rem auto 0 auto;
	padding: 0.5rem;
	width: 70%;
	font-family: Avenir-DemiBold;
	font-size: 1.1rem;
	border-radius: 5px;
	border: 0px transparent;
	background: #5bffb3;
}

.failureBtn {
	background: #ff5b5b;
}

.footer {
	display: flex;
	justify-content: center !important;
	align-items: center;
}
