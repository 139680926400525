.header {
	color: var(--heading-color2);
	padding: 01rem 0.5rem 0.5rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
}

.header h2 {
	margin: 2px 0 0 10px;
	flex: 1;
	font-size: 1.25rem;
	font-weight: 500;
}
.searchContainer {
	margin: 1rem 2rem 1rem 1rem;
	border: 1px solid #7f89be;
	max-width: 100%;
	padding: 3px 5px;
	border-radius: 6px;
	display: flex;
}

.searchIcon {
	margin: auto 0.5rem;
	margin-right: 0rem;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 0.9rem;
	font-weight: 400;
	flex: 1;
	color: #7f89be;
	background: transparent;
}

.input::placeholder {
	color: #7f89be;
}

.loader {
	margin: 0 auto;
	margin-bottom: 30px;
	width: 30%;
	justify-self: center;
	padding: 1rem 3rem;
	display: flex;
	justify-content: center !important;
	border-radius: 5px;
	border: 1px solid #eee;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.heading {
	margin: 2.5rem;
	display: flex;
}

.heading h1 {
	font-size: 3rem;
	font-weight: 600;
	color: #cccccc;
	cursor: pointer;
}

.folderCard {
	box-shadow: 0px 4px 8px #0d0f22;
	border-radius: 16px;
	padding: 10px;
	cursor: pointer;
	margin: 5px 0;
}

.folderHead {
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.folderHead h2 {
	font-weight: normal;
	font-size: 1.25rem;
	margin-bottom: 0;
}

.folderHead p {
	color: var(--text-primary);
	font-weight: normal;
	font-size: 1.25rem;
}

.thoughtCount {
	padding: 0 10px;
}

.thoughtCount p {
	/* color: var(--secondary); */
	font-size: 0.875rem;
}

.folderTime {
	padding-right: 10px;
	display: flex;
	justify-content: flex-end;
}

.folderTime p {
	color: var(--text-dark);
	font-size: 10px;
	font-weight: normal;
}

.cards {
	margin: 1rem 0 0 0.5rem;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: flex-start;
}

.bookmarks {
	height: 100%;
	width: 95%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.bookmarks::-webkit-scrollbar {
	display: none;
}

.ctaList {
	padding: 0 !important;
}

button {
	padding: 0;
}

.myprofileTab {
	background: #0d0f22;
	border: 1px solid #2e2e48;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	color: #d9def4;
	font-size: 17px;
}

.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.mobNav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #030413;
	/* padding: 7px; */
	padding: 0.7rem 1rem;
}

.headerText h2,
.headerText2 h2,
.headerText3 h2 {
	font-size: 17px;
}

@media screen and (max-width: 768px) {
	.cards {
		display: flex;
		width: 100%;
		margin-left: 0;
		flex-direction: row;
		justify-content: center;
		padding-bottom: 4.5rem;
	}
	.header {
		padding-top: 0.8rem;
	}
	.searchContainer {
		margin: 0;
		border: 1px solid #7f89be;
		max-width: 100%;
		padding: 3px 5px;
		border-radius: 6px;
		display: flex;
		padding: 5px 10px;
		width: 85%;
	}

	.input {
		margin-left: 0.6em;
		border: 0;
		font-size: 0.9rem;
		font-weight: 400;
		flex: 1;
		color: #7f89be;
		background: transparent;
	}

	.input::placeholder {
		color: #7f89be;
	}
	.darkPText {
		color: #d9def4 !important;
	}

	.lgPText {
		color: #0d0f22 !important;
	}

	.cardsContainer {
		margin: 1rem 0;
		padding: 5px 15px 5px 10px;
		overflow-x: hidden;
	}
}

.mobempty{
	position: absolute;
	top:60px;
	height: auto;
	margin-top: 5rem;
}
