$popover-width: 350px;
.dialog {
	width: $popover-width;
	position: relative;
}

.closeBtn {
	position: absolute;
	top: 10px;
	right: 10px;
}

.header {
	padding: 3rem 0 0 3.3rem;
	display: flex;
	width: 100%;
	h2 {
		font-size: 1.3rem;
		font-weight: 700;
	}
}

.status {
	padding: 1rem 3rem 0 3.3rem;
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.createFolder {
	padding: 1rem 0;
	display: flex;
	justify-content: center;
}

.folderContainer {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 370px;
	&::-webkit-scrollbar {
		width: 3px;
	}
}
@media screen and (max-width: 768px) {
	.dialog {
		width: 100%;
	}

	.folderContainer {
		.folder {
			max-width: 100%;
		}
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	padding: 1rem 0;
}
