.homeContainer {
	display: flex;
	position: relative;
	height: 100%;
}

.center {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 91.5vh;
	display: flex;
	overflow: hidden;
	position: relative;
}

.leftSideBar {
	padding: 1rem;
	width: 23%;
}

.centerContainer {
	width: 60%;
}

.rightSideBar {
	padding: 1rem;
	width: 20%;
}

.sidebarContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	opacity: 1;
	overflow: none;
	scrollbar-width: none;
	border-radius: 16px;
	padding: 1rem 0.5rem;
}

.grpContainer {
	margin: 1.25rem 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--backgroud-color);
}

.groupName {
	width: 100%;
	display: flex;
	align-items: center;
	/* cursor: pointer; */
}

.grpname {
	margin-left: 1rem;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	color: var(--heading-color2);
}

.homeContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.noStatusContainer {
	width: 67% !important;
	justify-content: center;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 8px;
	width: 100%;
	text-align: center;
}
