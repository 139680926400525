.container {
	margin: 6.5% 0;
}

.header {
	margin-bottom: 5%;
}
.header h2 {
	color: var(--heading-color2);
	font-size: 1.25rem;
}

.currentTheme {
	border: 1px solid #00c6ff;
}

.disabledTheme {
	border: 1px solid white;
}

.themeBoxContainer {
	display: flex;
	justify-content: space-around;
}

.themeBox {
	padding: 100px;
	background: var(--dark-background);
	padding: 140px 220px;
	border-radius: 1rem;
	box-shadow: 0px 4px 8px #0d0f22;
}

@media only screen and (max-width: 768px) {
	.themeBox {
		padding: 101px;
	}
}
