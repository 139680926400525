$popover-width: 350px;
.dialog {
	width: $popover-width;
	position: relative;
}

.closeBtn {
	position: absolute !important;
	top: 10px;
	right: 10px;
}

.header {
	padding: 3rem 0 1rem 1rem;
	display: flex;
	width: 100%;
	h2 {
		font-size: 1.3rem;
		font-weight: 700;
	}
}

.likersContainer {
	display: flex;
	flex-direction: column;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ff5d5d;
	}
}

.userCard {
	display: flex;
	padding: 0.7rem 0;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid #eee;
	color: black;
	// &:hover {
	// 	background-color: #eee;
	// }
	.rightContainer {
		height: 100%;

		padding-left: 1rem;
		p {
			margin-bottom: 0;
		}
		.username {
			font-weight: 500;
		}
		.profession {
			font-size: 0.8rem;
			color: #888;
		}
	}
}

.empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #bbb;
	&__icon {
		svg {
			font-size: 3.5rem;
		}
	}
}
