.container {
	display: flex;
	color: var(--heading-color2);
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	align-items: center;
	border-bottom: 1px solid #2e2e48;
}

.content {
	width: 100%;
	margin-left: 1rem;
	display: flex;
	/* justify-content: space-between; */
}

.name {
	margin: 0;
	flex: 1;
	font-size: 1.1rem;
	font-weight: 400;
	font-style: normal;
}

.post {
	font-size: 0.8rem;
	font-family: Roboto;
	margin: 0;
	/* display: flex;
  align-items: center; */
	color: #979797;
}

.image {
	position: relative;
}

.status {
	width: 0.75rem;
	height: 0.75rem;
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	z-index: 1000;
}

.red {
	background: rgb(255, 35, 31) !important;
}
.orange {
	background: rgb(255, 123, 0) !important;
}
.green {
	background: #44b700 !important;
}

.dark{
	color: #d9def4;
}

.lg{
	color: #0d0f22;
}

@media screen and (max-width: 600px) {
	.name {
		font-size: 1.2rem;
	}
	.post {
		font-size: 0.95rem;
	}
}
