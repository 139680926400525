.container {
	display: flex;
	justify-content: center;
	height: 10vh;
	max-height: 4rem;
}

.container:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.navbarContainer {
	width: 100%;
	max-width: 100rem;
	max-height: 4rem;
	display: flex;
	position: fixed;
	z-index: 4;
}

.left {
	display: flex;
	flex: 1;
}

.title {
	width: 24%;
	padding: 0.5rem 0.75rem;
}

.title img {
	width: 7rem;
	height: 100%;
}

.links {
	margin-left: 3rem;
	display: flex;
}

.navElementBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.navElement {
	margin-right: 2.25rem;
}

.underline {
	vertical-align: top;
}

.right {
	display: flex;
}

.right a:hover {
	text-decoration: none;
}

.search {
	margin-right: 2rem;
	height: 100%;
	display: flex;
	align-items: center;
	font-family: Roboto;
	align-items: center;
}

.searchIcon {
	cursor: pointer;
}

.radios {
	margin-top: 1rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #dfdfdf;
}

.labelHead {
	margin-left: 0.6rem;
	color: #9a9a9a;
	font-size: 1.08rem;
}
.labels {
	margin-left: -0.35em;
	margin-right: 0.4rem;
	margin-bottom: -0.1rem;
}
.locationContainer {
	margin-right: 2rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	/* border-left: 1px solid #eee; */
}
.user {
	flex: 1;
	display: flex;
	align-items: center;
	text-align: left;
	width: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	margin-top: 20px;
}

.upperMenu {
	border-bottom: 1px solid #2e2e48;
	width: 100%;
	padding-bottom: 8px;
	margin-bottom: 10px;
}

.header h2 {
	color: var(--heading-color2);
	font-size: 1.25rem;
}
.dialog {
	max-width: 400px !important;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
}

.textBox {
	padding: 20px;
	padding-bottom: 10px;
}
.textBox p {
	font-size: 14px;
	color: var(--heading-color2);
	margin-top: 4px;
}

@media only screen and (min-width: 1200px) {
	.left {
		padding-left: 0.7em;
	}
	.right {
		margin-left: -1.5%;
	}
	.user {
		margin-left: 1%;
		width: 100%;
	}
}

.notification {
	margin-right: 2rem;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
}

.notification a {
	display: flex;
	align-items: center;
}

.notificationIcon {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dot {
	height: 0.5rem;
	width: 0.5rem;
	position: absolute;
	top: 1rem;
	left: 1.9rem;
	background: #ff5b5b;
	border-radius: 50%;
}

.username {
	margin: 0;
	padding-left: 0.3rem;
	letter-spacing: 0.3px;
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 22px;
	/* identical to box height */

	color: var(--secondary);
}

.menuBox {
	display: flex;
	align-items: center;
}

.menuDark {
	color: #d9def4;
}

.menulg {
	color: #0d0f22;
}

.userMenu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.inputField {
	margin-top: 0.5rem;
}
@media only screen and (max-width: 600px) {
	.title {
		font-size: 1.5rem;
		width: 5.5rem;
		padding: 1rem 0.75rem;
	}

	.links {
		display: none;
	}

	.notification {
		color: #555;
		border: none;
		padding: 10px;
	}
	.search {
		padding: 10px;
	}

	.searchText {
		display: none;
	}

	.locationContainer {
		border: 0;
	}

	.username {
		display: none;
	}
}

.darkbg {
	background: #111528;
}

.lgbg {
	background: #fff;
}

.mobNavbar {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 17px;
	color: #d9def4;
	padding: 15px 22px;
	font-weight: 500;
}
