.contHide {
	transform: translateX(100%);
	display: none;
}

.contShow {
	width: 25%;
	display: flex;
	position: absolute;
	flex-direction: column;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 200;
	padding: 0.5rem;
	background-color: white;
	box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.02);
	transform: translateX(0);
	transition: transform 0.3s ease-out;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ff5b5b;
	}
}

.topContainer {
	display: flex;
	padding-right: 1rem;
	justify-content: space-between;
}

.header {
	padding: 1rem 0 0 0;
	h2 {
		font-size: 1.1rem;
		font-weight: 500;
	}
}

.body {
	width: 100%;
	transition: 0.5s;
	max-height: 50rem;
	display: flex;
	flex-direction: column;
	min-height: 10rem;
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	.loaderContainer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 1rem;
		.loader {
			font-size: 1rem;
			color: #ff5b5b;
		}
	}

	.readBy {
		padding-top: 1rem;
		.header {
			color: #ff5b5b;
			font-weight: 500;
			padding: 0.5rem;
			font-size: 1.2rem;
			position: sticky;
			display: flex;
			align-items: center;
			top: 0;
			z-index: 1;
			span {
				margin-left: 10px;
			}
		}
		.emptyList {
			padding: 0.5rem 1rem;
			text-align: left;
			font-size: 0.8rem;
			color: #aaa;
		}
	}
}

.userCard {
	display: flex;
	padding: 0.75rem 1rem;
	align-items: center;
	border-bottom: 1px solid #eee;
	.name {
		flex: 1;
		margin-left: 10px;
		color: #979797;
		font-size: 0.9rem;
	}
}

@media only screen and (max-width: 768px) {
	.contShow {
		position: absolute;
		width: 100%;
		background-color: white;
	}
}
